import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'

import Button from 'src/components/Button/Button'

export interface PanelSummaryContentProps {
  className?: string
  expandedPanel: number
  panelNo: number
  completed: boolean
  title: string
}

export const PanelSummaryContent: FC<PanelSummaryContentProps> = ({
  className,
  expandedPanel,
  title,
  panelNo,
  completed,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  })

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent={smDown ? 'center' : 'flex-start'}
    >
      <Grid item xs={12} md>
        <Typography
          variant="h3"
          component="h2"
          align={smDown ? 'center' : 'left'}
        >
          {title}
        </Typography>
      </Grid>

      {expandedPanel > panelNo && (
        <Grid item>
          <Button variant="contained" size="small">
            {t('General.edit')}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default PanelSummaryContent
