import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerRoot: {
      padding: theme.spacing(5, 0, 0),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 10, 0),
      },
    },
    sectionTitle: {
      margin: theme.spacing(0, 2.5),

      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 5),
      },
    },
    contractsGridItemRoot: {
      '&.MuiGrid-item': {
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    logoGridWrapper: {
      maxWidth: 350,
      width: '100%',
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold as number,
    },
    contractsGrid: {
      '&.MuiGrid-item': {
        padding: theme.spacing(5, 2.5),

        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(5, 8.5, 7),
        },
      },
    },
  }),

  { name: 'ContractList' },
)

export default useStyles
