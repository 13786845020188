import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    active: {},
  }),

  { name: 'NavItem' },
)

export default useStyles
