import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from '@material-ui/lab/Skeleton'

import { TestIds } from 'src/testIds'
import { getPrivacyPolicy, PrivacyPolicy } from 'src/services/api/api'
import { LegalDocument } from 'src/components/LegalDocument/LegalDocument'
import { Markdown } from 'src/components/Markdown/Markdown'
import Page from 'src/components/Page/Page'

import useStyles from 'src/components/Markdown/Markdown.styles'

const PrivacyTestIds = TestIds.views.privacy

const PrivacyView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: _privacyPolicy } = await getPrivacyPolicy()

        setPrivacyPolicy(_privacyPolicy)
      } catch (error) {
        setPrivacyPolicy(null)
      }
    }
    fetchData()
  }, [t])

  return (
    <Page
      data-test-id={PrivacyTestIds.pageWrapper}
      title={t('Views.Privacy.title')}
    >
      <LegalDocument title={t('Views.Privacy.header')}>
        {!privacyPolicy ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          <Markdown className={classes.textPageStyles}>
            {privacyPolicy.dataPrivacyText}
          </Markdown>
        )}
      </LegalDocument>
    </Page>
  )
}

export default PrivacyView
