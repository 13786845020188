import { makeStyles } from '@material-ui/core'

import { Theme } from '../../theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    titlePadding: {
      padding: theme.spacing(10, 0),

      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(20, 10, 10),
      },
    },

    contentPadding: {
      paddingTop: 0,
      paddingBottom: 0,

      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(10),
        paddingRight: theme.spacing(17.5),
        paddingLeft: theme.spacing(17.5),
      },

      [theme.breakpoints.up('xl')]: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
      },
    },

    titleCenter: {
      width: '100%',
      textAlign: 'center',
    },
  }),

  { name: 'LegalDocument' },
)

export default useStyles
