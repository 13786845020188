import { makeStyles } from '@material-ui/core'

import { BORDER_RADIUS } from 'src/theme/border-radius'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      padding: theme.spacing(15, 0),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(30, 0, 20),
      },
    },

    contactFormContentStripContainer: {
      padding: theme.spacing(15, 0, 5),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(20, 0, 10),
      },

      '& .MuiContainer-root': {
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },

    contactFormCardRoot: {
      borderRadius: 0,

      [theme.breakpoints.up('sm')]: {
        borderRadius: BORDER_RADIUS.medium,
      },
    },
  }),

  { name: 'ContactView' },
)

export default useStyles
