import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import type { FormikProps } from 'formik'
import IBAN from 'iban'
import * as Yup from 'yup'

import downloadBlob from 'src/utils/downloadBlob'
import { removeNullCharacters } from 'src/utils/removeNullCharacters'
import api from 'src/services/api'
import i18n from 'src/services/i18n/i18n'
import Button from 'src/components/Button/Button'
import { PanelSubmitRow } from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow'

import { MembershipFormType } from '../MembershipForm'

import useStyles from '../MembershipForm.styles'

export interface PanelBankDetailsType {
  accountHolder: string
  bankName: string
  debitConsent: boolean
  iban: string
  bic: string
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelBankDetailsType = {
  accountHolder: '',
  bankName: '',
  debitConsent: false,
  iban: '',
  bic: '',
}

export const validationSchema = Yup.object().shape({
  accountHolder: Yup.string()
    .required(i18n.t('MembershipForm.Fields.BankData.AccountHolder.required'))
    .min(2, i18n.t('MembershipForm.Fields.BankData.AccountHolder.required'))
    .max(255, i18n.t('General.Form.maxLength', { length: 255 })),
  bankName: Yup.string()
    .required(i18n.t('MembershipForm.Fields.BankData.BankName.required'))
    .min(2, i18n.t('MembershipForm.Fields.BankData.BankName.required'))
    .max(250, i18n.t('General.Form.maxLength', { length: 250 }))
    .transform((current) => removeNullCharacters(current)),
  iban: Yup.string()
    .required(i18n.t('MembershipForm.Fields.BankData.Iban.required'))
    .min(2, i18n.t('MembershipForm.Fields.BankData.Iban.required'))
    .transform((current) => removeNullCharacters(current))
    .test(
      'iban',
      i18n.t('MembershipForm.Fields.BankData.Iban.required'),
      (ibanString) => (ibanString ? IBAN.isValid(ibanString) : false),
    ),
  bic: Yup.string()
    .min(8, i18n.t('MembershipForm.Fields.BankData.Bic.format'))
    .max(11, i18n.t('MembershipForm.Fields.BankData.Bic.max'))
    .transform((current) => removeNullCharacters(current)),
  debitConsent: Yup.boolean().oneOf(
    [true],
    i18n.t('MembershipForm.Fields.BankData.DebitConsent.required'),
  ),
})

/* eslint-enable */

export interface PanelBankDetailProps {
  className?: string
  formik: FormikProps<MembershipFormType>
  submitEmpty: any
}

export const PanelBankDetail: FC<PanelBankDetailProps> = ({
  className,
  formik,
  submitEmpty,
  ...props
}) => {
  const { t } = useTranslation()
  const confirm = useConfirm()
  const params = useParams<{ regionSlug: string }>()
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()

  return (
    <Grid container>
      {formik.values.regionalMembershipHasFee ? (
        <>
          <fieldset className={classes.fieldset} {...props}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(
                    formik.touched.accountHolder && formik.errors.accountHolder,
                  )}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  helperText={
                    formik.touched.accountHolder && formik.errors.accountHolder
                  }
                  label={t(
                    'MembershipForm.Fields.BankData.AccountHolder.label',
                  )}
                  name="accountHolder"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.accountHolder}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(
                    formik.touched.bankName && formik.errors.bankName,
                  )}
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  helperText={formik.touched.bankName && formik.errors.bankName}
                  label={t('MembershipForm.Fields.BankData.BankName.label')}
                  name="bankName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.bankName}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(formik.touched.iban && formik.errors.iban)}
                  fullWidth
                  inputProps={{ maxLength: 34 }}
                  helperText={formik.touched.iban && formik.errors.iban}
                  label={t('MembershipForm.Fields.BankData.Iban.label')}
                  name="iban"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.iban}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(formik.touched.bic && formik.errors.bic)}
                  fullWidth
                  inputProps={{ maxLength: 11 }}
                  helperText={formik.touched.bic && formik.errors.bic}
                  label={t('MembershipForm.Fields.BankData.Bic.label')}
                  name="bic"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.bic}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Box py={2}>
                  <FormControl
                    error={Boolean(
                      formik.touched.debitConsent && formik.errors.debitConsent,
                    )}
                    required
                    variant="outlined"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.debitConsent}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="debitConsent"
                          />
                        }
                        label={t(
                          'MembershipForm.Fields.BankData.DebitConsent.label',
                        )}
                      />
                    </FormGroup>

                    {formik.touched.debitConsent &&
                      formik.errors.debitConsent && (
                        <FormHelperText>
                          {formik.errors.debitConsent}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Box>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={
                    !formik.values.debitConsent ||
                    !formik.values.accountHolder ||
                    !formik.values.iban
                  }
                  size="small"
                  startIcon={
                    loading && <CircularProgress size={20} color="inherit" />
                  }
                  onClick={() => {
                    setLoading(true)
                    const allValues = formik.values
                    const {
                      accountHolder,
                      bic,
                      iban,
                      personalCity,
                      personalPostalCode,
                      personalStreet,
                      personalStreetNo,
                    } = allValues
                    api
                      .downloadPreSepaMandateFile(
                        {
                          accountHolder,
                          bic,
                          city: personalCity,
                          iban,
                          postalCode: personalPostalCode,
                          street: personalStreet,
                          streetNo: personalStreetNo,
                        },
                        params.regionSlug,
                      )
                      .then((response) => {
                        const blob = new Blob([response.data])
                        const filename = `SEPA-Lastschriftmandat_${formik.values.accountHolder}_${params.regionSlug}.pdf`
                        downloadBlob(blob, filename)
                        setLoading(false)
                      })
                      .catch(() => {
                        setLoading(false)
                        confirm({
                          cancellationButtonProps: {
                            style: { display: 'none' },
                          },
                          content: (
                            <Alert severity="error">
                              <AlertTitle>
                                {t('General.somethingWentWrong')}
                              </AlertTitle>
                            </Alert>
                          ),
                          title: t('General.error'),
                        })
                      })
                  }}
                >
                  {t('MembershipForm.downloadPreSepaMandate')}
                </Button>
              </Grid>

              <PanelSubmitRow />
            </Grid>
          </fieldset>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md>
            <Typography align="center" gutterBottom>
              {t('Für Ihren Mitgliedschaftstyp ist kein Beitrag fällig.')}
            </Typography>
          </Grid>
          <Grid container item justifyContent="flex-end" xs={12}>
            <Grid item>
              <Button
                color="secondary"
                endIcon={<ChevronRightIcon />}
                onClick={submitEmpty}
                variant="contained"
              >
                {t('MembershipForm.next')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default PanelBankDetail
