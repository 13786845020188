import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    navItem: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }),

  { name: 'NavBar' },
)

export default useStyles
