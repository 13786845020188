import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Hidden,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import { SpecialContract } from 'src/types'
import { withHttps } from 'src/utils'
import Button from 'src/components/Button/Button'
import SpecialContractCardDialog from 'src/components/ContractCardDialog/SpecialContractCardDialog'
import Image from 'src/components/Image/Image'

import useStyles from './ContractCard.styles'

const ComponentTestIds = TestIds.components

export interface SpecialContractCardProps {
  specialContract: SpecialContract
  regionName: string | undefined
}

/**
 * This Card Component has a mobile and a desktop version.
 * The views differ too much from each other, so that 2 variants are necessary.
 */
export const SpecialContractCard: FC<SpecialContractCardProps> = ({
  specialContract,
  regionName,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  const [openModal, setOpenModal] = useState<boolean>()

  const Img = (
    <Image
      className={clsx(classes.cardImg)}
      src={specialContract.logoUrl || ''}
      alt={specialContract.abbreviation}
    />
  )

  const ButtonGroup = (
    <Grid spacing={1} container justifyContent="flex-start">
      <Grid
        item
        xs={12}
        sm="auto"
        container
        justifyContent={smUp ? 'flex-start' : 'center'}
      >
        <Grid item>
          <Button
            data-test-id={
              ComponentTestIds.specialContractCard.dialogDetailsButton
            }
            color="accent"
            size="small"
            variant="contained"
            onClick={() => setOpenModal(true)}
          >
            {t('ContractCard.buttonDetails')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Card
      className={clsx(classes.specialContractCardRoot)}
      {...props}
      elevation={10}
    >
      <Hidden xsDown>
        <CardContent
          component={Grid}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item sm={7}>
            <Grid container item direction="column" sm spacing={3}>
              <Grid item>
                <Typography variant="h3">
                  {specialContract.abbreviation}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {specialContract.teaser}
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  className={classes.linkRoot}
                  variant="body1"
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                  href={withHttps(specialContract.link1)}
                >
                  {specialContract.linkText1}
                </Link>
              </Grid>
              <Grid item>{ButtonGroup}</Grid>
            </Grid>
          </Grid>
          <Grid item sm={2}>
            {Img}
          </Grid>
        </CardContent>
      </Hidden>

      <Hidden smUp>
        <CardContent className={classes.cardContentMobileRoot}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              <Typography variant="h3" component="h2">
                {specialContract.abbreviation}
              </Typography>
            </Grid>
            <Grid item xs>
              {Img}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>{ButtonGroup}</CardActions>
      </Hidden>

      <SpecialContractCardDialog
        data-test-id={TestIds.components.specialContractDialog}
        contract={specialContract}
        contractLogo={Img}
        open={!!openModal}
        onClose={() => setOpenModal(false)}
        regionName={regionName}
      />
    </Card>
  )
}

export default SpecialContractCard
