import { colors } from '@material-ui/core'

export const TYPOGRAPHY = {
  fontFamily: 'Eina02, Arial, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightBold: 700,

  h1: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightBold
    },
    fontSize: 40,
    lineHeight: 1,
  },

  h2: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightBold
    },
    fontSize: 26,
    lineHeight: 1.3846153846,
  },

  h3: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightBold
    },
    fontSize: 22,
    lineHeight: 1.6363636364,
  },

  h4: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightBold
    },
    fontSize: 18,
    lineHeight: 1.3888888889,
  },

  subtitle1: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightRegular
    },
    fontSize: 22,
    lineHeight: 1.6363636364,
  },

  subtitle2: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightRegular
    },
    fontSize: 18,
    lineHeight: 1.3888888889,
  },

  body1: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightRegular
    },
    fontSize: 16,
    lineHeight: 1.5,
  },

  body2: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightBold
    },
    fontSize: 16,
    lineHeight: 1.5,
  },

  // PRE REDESIGN STYLES
  capital: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightRegular
    },
    marginTop: 20,
    fontSize: 20,
  },

  // Pre-Redesign styles
  h5: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightRegular
    },
    fontSize: 16,
  },

  h6: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightRegular
    },
    fontSize: 14,
  },

  caption: {
    color: colors.grey[600],
    fontSize: 12,
  },

  overline: {
    get fontWeight() {
      return TYPOGRAPHY.fontWeightRegular
    },
  },
}
