import type { Theme as MuiTheme } from '@material-ui/core'
import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core'
import type {
  Palette as MuiPalette,
  PaletteColor,
} from '@material-ui/core/styles/createPalette'
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows'
import _ from 'lodash'

import { THEMES } from 'src/constants'
import { BREAKPOINTS } from 'src/theme/breakpoints'

import { BORDER_RADIUS } from './border-radius'
import { COLORS } from './colors'
import { GRADIENTS } from './gradients'
import { OVERRIDES } from './overrides'
import { SHADOWS } from './shadows'
import { TYPOGRAPHY } from './typography'

export interface Palette extends MuiPalette {
  accent: PaletteColor
  tertiary: PaletteColor
  fog: PaletteColor
  lightGrey: PaletteColor
}

export interface Theme extends MuiTheme {
  name: string
  palette: Palette
  settings: Record<string, any>
  borderRadius: Record<string, any>
  gradients: Record<string, any>
}

interface ThemeConfig {
  responsiveFontSizes?: boolean
  theme?: string
}

interface ThemeOptions {
  borderRadius?: Record<string, any>
  gradients?: Record<string, any>
  name?: string
  props?: Record<string, any>
  breakpoints?: Record<string, any>
  overrides?: Record<string, any>
  palette?: Record<string, any>
  settings?: Record<string, any>
  shadows?: MuiShadows
  typography?: Record<string, any>
}
const baseOptions: ThemeOptions = {
  settings: {
    topBarHeight: 100,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  breakpoints: {
    values: BREAKPOINTS,
  },
  typography: TYPOGRAPHY,
}

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    palette: {
      type: 'light',
      ...COLORS,
    },
    overrides: OVERRIDES,
    shadows: SHADOWS.SOFT_SHADOWS,
    borderRadius: BORDER_RADIUS,
    gradients: GRADIENTS,
  },
]

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  let theme = createMuiTheme(_.merge({}, baseOptions, themeOptions))

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme as Theme
}
