import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: theme.spacing(10),

      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(20),
      },
    },

    hero: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10, 0),
      },
    },

    headline: {
      marginBottom: theme.spacing(1.5),
    },

    subtitle: {
      marginBottom: theme.spacing(4),
    },

    infoText: {
      marginTop: theme.spacing(5),
    },

    ctaContainer: {
      padding: theme.spacing(10, 0),

      '& .MuiContainer-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

    ctaBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(5),
      padding: theme.spacing(3),
      boxShadow: theme.shadows[10],
      borderTop: `8px solid ${theme.palette.primary.main}`,
      borderBottom: `8px solid ${theme.palette.primary.main}`,

      [theme.breakpoints.up('md')]: {
        border: `8px solid ${theme.palette.primary.main}`,
        borderRadius: theme.borderRadius.medium,
      },

      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },

    howItWorksContainer: {
      paddingBottom: theme.spacing(15),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(10, 0, 20),
      },
    },

    badgeAndTextContainer: {
      textAlign: 'center',
    },

    numberBadge: {
      marginBottom: theme.spacing(2.5),
    },

    infoButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),

  { name: 'MembershipView' },
)

export default useStyles
