import type { FC, ReactNode } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import Button from 'src/components/Button/Button'
import type { ContentStripeProps } from 'src/components/ContentStripe/ContentStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import CustomIcon from 'src/components/CustomIcon/CustomIcon'

import useStyles from './ContactStripe.styles'

export interface ContactStripeProps
  extends Omit<ContentStripeProps, 'children'> {
  children?: ReactNode
  regionPhone?: string | null | undefined
  serviceHoursRow1?: string | null | undefined
  serviceHoursRow2?: string | null | undefined
  variant?: 'primary' | 'accent'
}

export const ContactStripe: FC<ContactStripeProps> = ({
  className,
  children,
  regionPhone,
  serviceHoursRow1,
  serviceHoursRow2,
  variant = 'primary',
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // Removes spaces, hyphens and slashes from phone numbers to be able to use it as phone links
  const formatPhoneNumber = (phone: string): string => {
    return phone.replace(/[\s-/]/g, '')
  }

  return (
    <ContentStripe
      className={clsx(classes.root, className)}
      data-test-id={TestIds.components.contactStripe}
      backgroundColor={variant === 'accent' ? 'accentLight' : 'primaryLight'}
      {...props}
    >
      <Grid container justifyContent={'center'} alignItems="center">
        <Grid className={classes.contactInfosContainer} item>
          <Box>
            <Typography variant="h3" component="h5">
              {t('ContactStripe.header')}
            </Typography>
            <Typography variant={'subtitle1'}>
              <CustomIcon
                className={classes.phoneIcon}
                iconName={'telefon'}
                color="textPrimary"
              />
              <a
                className={classes.phoneLink}
                href={`tel:${formatPhoneNumber(
                  regionPhone || t('Views.Contact.hzvContactPhone'),
                )}`}
              >
                {regionPhone || t('Views.Contact.hzvContactPhone')}
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography>
              {serviceHoursRow1
                ? serviceHoursRow1
                : t('ContactStripe.openingHours01')}
            </Typography>
            <Typography>
              {serviceHoursRow1
                ? serviceHoursRow2
                : t('ContactStripe.openingHours02')}
            </Typography>
          </Box>
          <Button
            color={variant === 'accent' ? 'accent' : 'primary'}
            component={RouterLink}
            to={routes.root.routes!.contact.path}
            variant="contained"
          >
            {t('ContactStripe.button')}
          </Button>
        </Grid>
      </Grid>
    </ContentStripe>
  )
}

export default ContactStripe
