import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
    },

    toolbar: {
      height: theme.settings.topBarHeight,
    },

    navBarContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },

    homeButton: {
      fontSize: 24,
      padding: 0,

      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
      },

      '&:hover': {
        backgroundColor: 'unset',
      },
    },

    active: {
      fontWeight: theme.typography.fontWeightBold as number | undefined,
    },

    drawer: {
      position: 'absolute',
    },

    drawerAnchor: {
      top: theme.settings.topBarHeight,
    },

    // Override drawer modal postion and z-index, so it is displayed beneath the top bar.
    drawerModal: {
      position: [['absolute'], '!important'] as any,
      zIndex: [[theme.zIndex.appBar - 1], '!important'] as any,
    },
  }),
  { name: 'TopBar' },
)

export default useStyles
