import type { FC, SetStateAction } from 'react'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { add, format } from 'date-fns'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import type { Membership } from 'src/types'
import { MembershipDateOption } from 'src/types'
import { isPDFValid, PDF_FILE_TYPE } from 'src/utils/isPDFValid'
import i18n from 'src/services/i18n/i18n'
import Button from 'src/components/Button/Button'
import { PanelSubmitRow } from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow'
import { KeyboardDatePicker } from 'src/components/DatePicker/KeyboardDatePicker'
import ErrorDialog from 'src/components/ErrorDialog/ErrorDialog'

import { MembershipFormType } from '../MembershipForm'

import useStyles from '../MembershipForm.styles'

export interface PanelTypeOfMembershipType {
  regionalMembershipId: number | null
  regionalMembershipDate: any
  regionalMembershipAttachment: any
  regionalMembershipDateOption: number | null | undefined
  regionalMembershipHasFee: boolean | undefined
  regionalMembershipHasUpload: boolean
  regionalMembershipIsPracticeAddressRequired: boolean | undefined
  regionalMembershipPracticeAddressLabel: string | undefined
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelTypeOfMembershipType = {
  regionalMembershipId: null,
  regionalMembershipDate: null, // Must be 'null' or the field is pre-filled with todays date.
  regionalMembershipAttachment: null,
  regionalMembershipDateOption: undefined,
  regionalMembershipHasFee: false,
  regionalMembershipHasUpload: false,
  regionalMembershipIsPracticeAddressRequired: false,
  regionalMembershipPracticeAddressLabel: undefined,
}

const today = new Date()
const maxDate = format(add(today, { years: 100 }), 'yyyy-MM-dd')
const minDate = format(today, 'yyyy-MM-dd')
const maxFileSize = 20000 * 1024 // 20 MB

export const validationSchema = Yup.object().shape({
  regionalMembershipDateOption: Yup.number(),
  regionalMembershipHasUpload: Yup.boolean(),
  regionalMembershipHasFee: Yup.boolean(),
  regionalMembershipId: Yup.string()
    .nullable()
    .required(
      i18n.t(
        'MembershipForm.Fields.TypeOfMembership.RegionalMembershipId.required',
      ),
    ),
  regionalMembershipDate: Yup.date()
    .when('regionalMembershipDateOption', {
      is:
        MembershipDateOption.REQUIRED ||
        MembershipDateOption.REQUIRED_ONLY_FUTURE,
      then: Yup.date()
        .typeError(
          i18n.t(
            'MembershipForm.Fields.TypeOfMembership.RegionalMembershipDate.invalid',
          ),
        )
        .nullable()
        .required(
          i18n.t(
            'MembershipForm.Fields.TypeOfMembership.RegionalMembershipDate.required',
          ),
        ),
    })
    .when('regionalMembershipDateOption', {
      is: MembershipDateOption.REQUIRED_ONLY_FUTURE,
      then: Yup.date().min(
        minDate,
        i18n.t(
          'MembershipForm.Fields.TypeOfMembership.RegionalMembershipDate.max',
        ),
      ),
    })
    .nullable(),
  regionalMembershipAttachment: Yup.mixed()
    .when('regionalMembershipHasUpload', {
      is: true,
      then: Yup.mixed().required(
        i18n.t(
          'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.required',
        ),
      ),
    })
    .test(
      'fileSize',
      i18n.t(
        'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.size',
      ),
      (val) => (!!val ? val.size < maxFileSize : true),
    ),
})

/**
 * @prop {setCompletedPanel} useState set Method to reset form completed steps, when membership changes to prevent skipping required fields in other steps
 */
export interface PanelTypeOfMembershipProps {
  className?: string
  // memberships is undefined if Region is undefined
  memberships: Membership[] | undefined
  membershipInformation: string | null | undefined
  formik: FormikProps<MembershipFormType>
  setCompletedPanel: (completed: SetStateAction<number>) => void
}

export const PanelTypeOfMembership: FC<PanelTypeOfMembershipProps> = ({
  className,
  formik,
  memberships,
  membershipInformation,
  setCompletedPanel,
  ...props
}) => {
  const regionalAttachmentDescribeById = 'regional-attachment-describe-text-id'
  const { t } = useTranslation()
  const classes = useStyles()
  const uploadInputRef = useRef<HTMLInputElement>(null)

  const [dialogErrorMessage, openErrorDialogWithMessage] = useState<string>('')

  /**
   * The Formik field "regionalMembershipDateOption" and "regionalMembershipHasFee" is set here
   * so that the field "regionalMembershipDate" can be rendered if
   * the value "dateOption" of a selected membership is 1 == None or 3 == Required.
   * Also the yup schema can validate the datePiker conditionally.
   */
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    const selectedMembership = memberships?.find(
      (membership) => membership.id === parseInt(value),
    )
    selectedMembership &&
      formik.setValues({
        ...formik.values,
        regionalMembershipDate: null, // set date to null if the RadioGroup is changed
        regionalMembershipAttachment: null, // set date to null if the RadioGroup is changed
        regionalMembershipId: selectedMembership.id,
        regionalMembershipDateOption: selectedMembership?.dateOption,
        regionalMembershipHasUpload: selectedMembership?.hasUpload,
        regionalMembershipHasFee: selectedMembership?.hasFee,
        regionalMembershipIsPracticeAddressRequired:
          selectedMembership?.isPracticeAddressRequired,
        regionalMembershipPracticeAddressLabel:
          selectedMembership?.practiceAddressLabel,
      })
    setCompletedPanel(0)
  }

  const selectedMembership = formik.values.regionalMembershipId
    ? memberships?.find(
        (membership) => membership.id === formik.values.regionalMembershipId,
      )
    : undefined

  const getRadioLabel = (regionalMembership: Membership) => {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="h4" component="span">
          {regionalMembership.title}
        </Typography>
        <Typography component="span">
          {regionalMembership.hasFee
            ? regionalMembership.feeLabel
            : t('MembershipForm.nonContributory')}
        </Typography>
      </Box>
    )
  }

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl
            error={Boolean(
              formik.touched.regionalMembershipId &&
                formik.errors.regionalMembershipId,
            )}
            fullWidth
            required
          >
            <RadioGroup
              name="regionalMembershipId"
              onChange={handleChange}
              value={formik.values.regionalMembershipId}
            >
              <Grid container spacing={1}>
                {memberships?.map((regionalMembership) => (
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      key={regionalMembership.id}
                      control={<Radio />}
                      label={getRadioLabel(regionalMembership)}
                      value={regionalMembership.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>

            {formik.touched.regionalMembershipId &&
              formik.errors.regionalMembershipId && (
                <FormHelperText>
                  {formik.errors.regionalMembershipId}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>

        {/* Erforderlicher Nachweis */}
        {formik.values.regionalMembershipHasUpload && (
          <Grid item xs={12}>
            <Box mt={4} mb={-1}>
              <Typography variant="h4" component="h3">
                {t('MembershipForm.requiredProof')}
              </Typography>
            </Box>
          </Grid>
        )}
        {formik.values.regionalMembershipDateOption &&
          formik.values.regionalMembershipDateOption !==
            MembershipDateOption.NONE && (
            <>
              <Grid item xs={12} md={4}>
                <Typography gutterBottom>
                  <strong>{selectedMembership?.dateLabel || ''}</strong>
                </Typography>
                <KeyboardDatePicker
                  error={Boolean(
                    formik.touched.regionalMembershipDate &&
                      formik.errors.regionalMembershipDate,
                  )}
                  helperText={
                    formik.touched.regionalMembershipDate &&
                    formik.errors.regionalMembershipDate
                  }
                  label="Datum"
                  maxDate={maxDate}
                  minDate={
                    Number(formik.values.regionalMembershipDateOption) ===
                    Number(MembershipDateOption.REQUIRED_ONLY_FUTURE)
                      ? minDate
                      : undefined
                  }
                  name="regionalMembershipDate"
                  onBlur={formik.handleBlur}
                  onDateChange={(value) =>
                    formik.setFieldValue('regionalMembershipDate', value)
                  }
                  value={formik.values.regionalMembershipDate}
                  required={
                    Number(formik.values.regionalMembershipDateOption) ===
                      Number(MembershipDateOption.REQUIRED_ONLY_FUTURE) ||
                    Number(formik.values.regionalMembershipDateOption) ===
                      Number(MembershipDateOption.REQUIRED)
                  }
                />
              </Grid>
              <Grid item xs={12} md={2} />
            </>
          )}
        {formik.values.regionalMembershipHasUpload && (
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>
              <strong>{selectedMembership?.uploadLabel || ''}</strong>
            </Typography>
            <input
              hidden
              type={'file'}
              multiple={false}
              accept={'application/pdf, image/jpg, image/jpeg, image/png'}
              name={'regionalMembershipAttachment'}
              ref={uploadInputRef}
              onChange={async (event) => {
                if (event?.target?.files?.length) {
                  const file = event?.target?.files[0]
                  if (file.type === PDF_FILE_TYPE) {
                    const isValid = await isPDFValid(file)
                    if (!isValid) {
                      formik.setFieldValue('regionalMembershipAttachment', null)
                      uploadInputRef.current!.value = ''
                      openErrorDialogWithMessage(
                        t(
                          'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.uploadError.message',

                          { name: file.name },
                        ),
                      )
                      return
                    }
                  }
                  formik.setFieldValue('regionalMembershipAttachment', file)
                }
              }}
              aria-invalid={
                formik.touched.regionalMembershipAttachment &&
                !!formik.errors.regionalMembershipAttachment
              }
              aria-describedby={regionalAttachmentDescribeById}
            />
            <Button
              variant={'contained'}
              size="small"
              onClick={() =>
                uploadInputRef?.current && uploadInputRef?.current?.click()
              }
            >
              {t(
                'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.upload',
              )}
            </Button>

            <FormHelperText
              id={regionalAttachmentDescribeById}
              error={
                !!formik.touched.regionalMembershipAttachment &&
                !!formik.errors.regionalMembershipAttachment
              }
            >
              {(formik.touched.regionalMembershipAttachment &&
                formik.errors.regionalMembershipAttachment) ??
                formik.values?.regionalMembershipAttachment?.name}
            </FormHelperText>
          </Grid>
        )}
        {membershipInformation && (
          <Grid item xs={12}>
            <Box mt={5}>
              <Typography component="span">{membershipInformation}</Typography>
            </Box>
          </Grid>
        )}

        <PanelSubmitRow />
      </Grid>
      <ErrorDialog
        onClose={() => openErrorDialogWithMessage('')}
        open={!!dialogErrorMessage}
        title={t(
          'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.uploadError.title',
        )}
        errorMessage={dialogErrorMessage}
      />
    </fieldset>
  )
}

export default PanelTypeOfMembership
