import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'

import routes from 'src/routes'
import useContracts from 'src/hooks/useContracts'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Logo from 'src/components/Logo/Logo'
import NavBar from 'src/components/NavBar/NavBar'
import type { NavItemProps } from 'src/components/NavItem/NavItem'

import useStyles from './Footer.styles'

export interface FooterProps {
  className?: string
}

export const Footer: FC<FooterProps> = ({ className, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [basketHeight, setBasketHeight] = useState<number>()
  const { getters: contractGetters } = useContracts()
  const hasBasket =
    !!contractGetters.getSubSetOfSelectedAndFilteredContracts().length

  const navItems: NavItemProps[] = [
    {
      title: t('Footer.NavItems.Contact.title'),
      to: routes.root.routes!.contact.path,
    },
    {
      title: t('Footer.NavItems.GTC.title'),
      to: routes.root.routes!.gtc.path,
    },
    {
      title: t('Footer.NavItems.Imprint.title'),
      to: routes.root.routes!.legalNotice.path,
    },
    {
      title: t('Footer.NavItems.Privacy.title'),
      to: routes.root.routes!.privacy.path,
    },
  ]

  // get the Basket height
  useEffect(() => {
    const heightOfBasketWrapper =
      document.getElementById('basketWrapper')?.clientHeight

    setBasketHeight(heightOfBasketWrapper)
  }, [hasBasket])

  return (
    <footer className={clsx(classes.root, className)} {...props}>
      <ContentStripe className={classes.contentContainer}>
        <Grid container className={classes.centeredContainer}>
          <Grid className={classes.logoContainer} item>
            <Logo variant="dhv-primary-black" height={58} />
            <Logo variant="hzv-accent-black" height={58} />
          </Grid>
        </Grid>
      </ContentStripe>
      <ContentStripe
        style={{ marginBottom: hasBasket ? basketHeight : 0, paddingTop: 0 }}
      >
        <Grid className={classes.centeredContainer} container>
          <NavBar className={classes.navBar} navItems={navItems} />
        </Grid>
      </ContentStripe>
    </footer>
  )
}

export default Footer
