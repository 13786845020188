import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    '@global': {
      '@font-face': [
        {
          fontFamily: 'fontello',
          src: 'url(/static/fonts/fontello.woff2) format("woff2")',
        },
        {
          fontFamily: 'fontello redesign',
          src: 'url(/static/fonts/fontello-redesign/font/fontello-redesign.woff2) format("woff2")',
        },
      ],
    },
    root: {
      fontSize: '45px',
      fontFamily: 'fontello',
      color: theme.palette.primary.main,
    },
    fontello: {
      fontFamily: 'fontello',
    },
    fontelloRedesign: {
      fontFamily: 'fontello redesign',
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    textPrimary: {
      color: theme.palette.text.primary,
    },
    white: {
      color: theme.palette.common.white,
    },
    accent: {
      color: theme.palette.accent.main,
    },
  }),
  { name: 'CustomIcon' },
)

export default useStyles
