import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(10, 0, 4),
    },

    contentContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(10),
      },
    },

    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      gap: theme.spacing(5),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        gap: theme.spacing(10),
      },
    },

    centeredContainer: {
      display: 'flex',

      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },

    navBar: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },

      '& .MuiButton-root': {
        minWidth: 0,
        justifyContent: 'start',
      },
    },
  }),

  { name: 'Footer' },
)

export default useStyles
