import { colors } from '@material-ui/core'

import { BREAKPOINTS } from 'src/theme/breakpoints'

import { BORDER_RADIUS } from './border-radius'
import { COLORS } from './colors'
import { TYPOGRAPHY } from './typography'

export const OVERRIDES = {
  MuiButton: {
    root: {
      textTransform: 'none',
      textAlign: 'center',
      borderRadius: 60,
      fontSize: 16,
      padding: '14px 20px',
    },
    contained: {
      fontSize: 16,
      lineHeight: '32px',
      fontWeight: TYPOGRAPHY.fontWeightBold,
      backgroundColor: COLORS.lightGrey.main,
      color: COLORS.text.primary,

      '& .MuiButton-startIcon': {
        marginRight: '16px',
      },
    },
    sizeLarge: {
      fontSize: 16,
      padding: '20px 40px',
      maxHeight: 76,
    },
    sizeSmall: {
      fontSize: 16,
      padding: '8px 20px',
    },
  },
  MuiCard: {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 20px 0',

      [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
        padding: '40px 40px 0',
      },
    },
  },
  MuiCardHeader: {
    root: {
      padding: '0 0 40px',
    },
  },
  MuiCardContent: {
    root: {
      padding: '0 0 40px',
      flexGrow: 1,

      '&:last-child': {
        paddingBottom: '40px',
      },
    },
  },
  MuiCardActions: {
    root: {
      padding: '0 0 40px',
    },
  },
  MuiChip: {
    root: {
      backgroundColor: 'rgba(0,0,0,0.075)',
    },
  },
  MuiDialogActions: {
    root: {
      padding: 16,
    },
    spacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
  },
  MuiDialogContent: {
    root: {
      paddingRight: 16,
      paddingLeft: 16,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  MuiDialogTitle: {
    root: {
      paddingRight: 16,
      paddingLeft: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  MuiDivider: {
    light: {
      backgroundColor: colors.common.white + '3',
    },
  },
  MuiFormControl: {
    root: {
      '& .MuiFormControlLabel-label': {
        lineHeight: 1.4, // keep lines visually close in case label breaks
      },
    },
    fullWidth: {
      // Fixes labels being full width as well which led to invisible clickable areas.
      '& .MuiFormControlLabel-root': {
        paddingRight: 16,
        marginRight: 'auto',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      '&:first-letter': {
        textTransform: 'uppercase',
      },
      // Display multiple errors as list items. The TextField error wrapper can't be changed to 'ul'.
      '&.Mui-error > span': {
        marginLeft: 16,
        display: 'list-item',
      },
    },
  },
  MuiFormLabel: {
    root: {
      '&:not(.MuiInputLabel-root)': {
        ...TYPOGRAPHY.h4,
      },
      color: COLORS.text.primary,

      '&.Mui-focused': {
        color: COLORS.text.primary,
      },
    },
  },
  MuiRadio: {
    root: {
      color: COLORS.text.primary,
      padding: 8,
      marginRight: 4,
    },

    colorSecondary: {
      '&$checked': {
        color: COLORS.text.primary,
      },
    },
  },
  MuiInputBase: {
    input: {
      '&::placeholder': {
        opacity: 1,
        color: colors.blueGrey[600],
      },
    },
  },
  MuiInputLabel: {
    root: {
      color: '#595959',
    },
    outlined: {
      transform: 'translate(20px, 20px) scale(1)',
    },
    // Fixes an issue where select labels can overflow the select input.
    formControl: {
      maxWidth: 'calc(100% - 35px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  MuiOutlinedInput: {
    root: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
      },
    },
  },
  MuiSwitch: {
    root: {
      height: '56px', // 32px + 2 * 12px padding
      width: '72px', // 48px + 2 * 12px padding
    },
    track: {
      borderRadius: '100px', // fully rounded
      backgroundColor: 'black',
      opacity: 0.1,
    },
    switchBase: {
      top: '10px',
      left: '5px',
      paddingTop: '4px',
      paddingBottom: '4px',

      '&.Mui-checked': {
        transform: 'translateX(16px)',
      },
    },
    colorSecondary: {
      '&.Mui-checked': {
        color: 'white', // thumb color
      },
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    thumb: {
      height: '28px',
      width: '28px',
    },
  },
  MuiLinearProgress: {
    root: {
      borderRadius: 3,
      overflow: 'hidden',
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 32,
    },
  },
  MuiTableCell: {
    root: {
      '& .MuiTypography-body1': {
        fontSize: 'inherit', // Fixes different default font-sizes when using the Typography component inside a table cell.
      },
    },
    head: {
      get fontWeight() {
        return TYPOGRAPHY.fontWeightBold
      },
    },
  },
  MuiAccordion: {
    root: {
      padding: '30px 40px',
      margin: '40px 0',

      '&:before': {
        content: 'normal', // removes default borders
      },

      '&.Mui-expanded': {
        [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
          padding: '30px 20px',
        },

        [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
          margin: '40px 0',
        },
      },

      '&.MuiAccordion-rounded': {
        borderRadius: 0,

        [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
          borderRadius: BORDER_RADIUS.medium,
        },

        '&.MuiAccordion-rounded:first-child': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,

          [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
            borderTopLeftRadius: BORDER_RADIUS.medium,
            borderTopRightRadius: BORDER_RADIUS.medium,
          },
        },

        '&.MuiAccordion-rounded:last-child': {
          [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
            borderBottomLeftRadius: BORDER_RADIUS.medium,
            borderBottomRightRadius: BORDER_RADIUS.medium,
          },
        },
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    content: {
      margin: 0,

      '&.Mui-expanded': {
        margin: '0 0 40px',
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: 0,
    },
  },
  MuiTooltip: {
    tooltip: {
      ...TYPOGRAPHY.body1,
    },
  },
}
