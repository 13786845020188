import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import type { FilterOptionsState } from '@material-ui/lab'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import useContracts from 'src/hooks/useContracts'

import useStyles from './InsuranceFilter.styles'

export interface InsuranceFilterProps {
  className?: string
  insuranceCompanies: string[]
}

export interface InsuranceCompanyOption {
  title: string
  value: string
}

export const InsuranceFilter: FC<InsuranceFilterProps> = ({
  className,
  insuranceCompanies,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { actions: contractActions, state: contractState } = useContracts()
  const [value, setValue] = useState<InsuranceCompanyOption[]>([])
  const selectedFilters = contractState.contractList.selectedFilters
  const allSelected = insuranceCompanies.length === selectedFilters.length
  const selectAllValue = 'select-all'

  // Map the list of insurance companies to usable filter options for AutoComplete.
  const insuranceCompanyOptions: InsuranceCompanyOption[] =
    insuranceCompanies.map((insuranceCompany: string) => ({
      title: insuranceCompany,
      value: insuranceCompany,
    }))

  // Adds the "select all" option to the current filterOptions.
  const addSelectAllAndFilterOptions = (
    options: InsuranceCompanyOption[],
    state: FilterOptionsState<InsuranceCompanyOption>,
  ) => {
    const selectAllOption = {
      title: t('ContractInsuranceFilter.selectAllTitle'),
      value: selectAllValue,
    }
    const filteredOptions = options.filter((option) =>
      option.title.toLowerCase().includes(state.inputValue.toLowerCase()),
    )

    // Remove SelectAllOptions as soon as the Options are filtered
    return state.inputValue
      ? filteredOptions
      : [selectAllOption, ...filteredOptions]
  }

  // Handles the selection of filter options updating the autocomplete and contractContext accordingly.
  const handleChange = (
    event: React.ChangeEvent<{}>,
    options: InsuranceCompanyOption[],
  ) => {
    // Check if the "select all" option was selected.
    if (options.some((option) => option.value === selectAllValue)) {
      // Toggle the selection of all options.
      const _options = allSelected ? [] : insuranceCompanyOptions

      // Update the selected options for autocomplete and the contractContext
      setValue(_options)
      contractActions.setSelectedFilters(
        _options.map((selectedValue) => selectedValue.title),
      )
    } else {
      // Update the selected options for autocomplete and the contractContext
      setValue(options)
      contractActions.setSelectedFilters(
        options.map((selectedValue) => selectedValue.title),
      )
    }
  }

  // Renders the checkbox options and also takes care of the "checked" state for the "select all" option.
  const optionRenderer = (
    option: InsuranceCompanyOption,
    { selected }: any,
  ) => (
    <>
      <Checkbox
        checked={option.value === selectAllValue ? allSelected : selected}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        color="primary"
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        style={{ marginRight: 8 }}
      />

      {option.title}
    </>
  )

  // Populate auto complete value from ContractContext on mount.
  useEffect(() => {
    setValue(
      selectedFilters.map((insuranceCompany: string) => ({
        title: insuranceCompany,
        value: insuranceCompany,
      })),
    )
  }, [selectedFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      classes={{ root: clsx(classes.inputFocused) }}
      className={clsx(classes.root, className)}
      data-test-id={TestIds.components.insuranceFilter}
      disableCloseOnSelect
      filterOptions={addSelectAllAndFilterOptions}
      getOptionLabel={(insurance) => insurance.title}
      getOptionSelected={(option, _option) => option.value === _option.value}
      limitTags={1}
      multiple
      onChange={handleChange}
      options={insuranceCompanyOptions}
      renderOption={optionRenderer}
      renderInput={(params) => (
        <TextField
          label={t('ContractInsuranceFilter.selectLabel')}
          variant="outlined"
          {...params}
        />
      )}
      value={value}
      {...props}
    />
  )
}

export default InsuranceFilter
