import type { FC } from 'react'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  generatePath,
  Link as RouterLink,
  useLocation,
  useParams,
} from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { t } from 'i18next'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import { Region, RegionStatus } from 'src/types'
import useRegions from 'src/hooks/useRegions'
import useUserDate from 'src/hooks/useUserDate'
import api from 'src/services/api'
import Button from 'src/components/Button/Button'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import CustomIcon, {
  CustomIconsNameProp,
} from 'src/components/CustomIcon/CustomIcon'
import GradientBorderWrapper from 'src/components/GradientBorderWrapper/GradientBorderWrapper'
import Image from 'src/components/Image/Image'
import Page from 'src/components/Page/Page'

import useStyles from './FormSuccessView.styles'

const FormSuccessIds = TestIds.views.formSuccess

const MEMBERSHIP_CONTEXT = 'membership'
const CONTRACT_CONTEXT = 'contracts'

interface NextStep {
  icon: CustomIconsNameProp
  label: string
}

const contract_next_steps: NextStep[] = [
  {
    icon: 'daumen',
    label: t('Views.FormSuccess.contractNextSteps.step1'),
  },
  {
    icon: 'haken',
    label: t('Views.FormSuccess.contractNextSteps.step2'),
  },
  {
    icon: 'klemmbrett',
    label: t('Views.FormSuccess.contractNextSteps.step3'),
  },
  {
    icon: 'edelstein',
    label: t('Views.FormSuccess.contractNextSteps.step4'),
  },
]

const membership_next_steps: NextStep[] = [
  {
    icon: 'daumen',
    label: t('Views.FormSuccess.membershipNextSteps.step1'),
  },
  {
    icon: 'haken',
    label: t('Views.FormSuccess.membershipNextSteps.step2'),
  },
  {
    icon: 'klemmbrett',
    label: t('Views.FormSuccess.membershipNextSteps.step3'),
  },
]

const FormSuccessView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { hasMembershipStatus } = useUserDate()
  const { getters: regionGetters } = useRegions()
  const [loading, setLoading] = useState<boolean>(false)
  const location = useLocation()
  const confirm = useConfirm()
  const { applicationId, regionSlug, employmentType } = useParams<{
    applicationId?: string
    regionSlug?: string
    employmentType?: string
  }>()

  const region = regionGetters.getRegion({
    by: 'slug',
    value: regionSlug || '',
  })

  const context = location.pathname.includes('vertraege')
    ? CONTRACT_CONTEXT
    : location.pathname.includes('mitglied')
    ? MEMBERSHIP_CONTEXT
    : undefined

  const handleClick = (region: Region) => {
    let redirectPath = ''

    if (context === CONTRACT_CONTEXT) {
      if (region.membershipStatus === RegionStatus.PASSIVE) {
        redirectPath = generatePath(routes.root.routes!.membership.path)
      } else {
        redirectPath = generatePath(
          routes.root.routes!.membership.routes!.membershipForm.path,
          {
            regionSlug: regionSlug,
          },
        )
      }
    } else if (context === MEMBERSHIP_CONTEXT) {
      if (region.contractStatus === RegionStatus.PASSIVE) {
        redirectPath = generatePath(routes.root.routes!.contracts.path)
      } else {
        redirectPath = generatePath(
          routes.root.routes!.contracts.routes!.contractList.path,
          {
            regionSlug: regionSlug,
          },
        )
      }
    }
    return redirectPath
  }

  const filenameSuffix =
    context === CONTRACT_CONTEXT ? 'HZV-Antrag' : 'Mitgliedsantrag'

  const downloadContext =
    context === CONTRACT_CONTEXT ? 'applications' : 'memberships'

  const stepsList =
    context === CONTRACT_CONTEXT ? contract_next_steps : membership_next_steps

  const isOfficeOwnerEmploymentType = employmentType === '1'

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })

  return (
    <Page
      className={classes.root}
      data-test-id={FormSuccessIds.pageWrapper}
      title={t('Views.FormSuccess.title')}
    >
      {/* Hero */}
      <ContentStripe className={classes.hero}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography variant="h1" align="center" color="primary">
              {t('Views.FormSuccess.header')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      {/* Employer Auth Info */}
      {region?.contractApplicationRequiresEmployerAuthorisation &&
        !isOfficeOwnerEmploymentType &&
        context === CONTRACT_CONTEXT && (
          <ContentStripe className={classes.infoContainer}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={9}>
                <GradientBorderWrapper className={classes.gradientWrapper}>
                  <Box className={classes.infoBox}>
                    <Typography variant="h3">
                      {t(
                        'Views.FormSuccess.contractNextSteps.employerAuthInformation.title',
                      )}
                    </Typography>
                    <Typography variant="subtitle1">
                      <Trans
                        i18nKey="Views.FormSuccess.contractNextSteps.employerAuthInformation.description"
                        components={{
                          bold: <b />,
                        }}
                      />
                    </Typography>
                  </Box>
                </GradientBorderWrapper>
              </Grid>
            </Grid>
          </ContentStripe>
        )}

      {/* Buttons */}
      <ContentStripe className={classes.buttonContainer}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid
            className={classes.buttonWrapper}
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            {applicationId && (
              <Button
                variant="contained"
                color={context === CONTRACT_CONTEXT ? 'accent' : 'primary'}
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CustomIcon
                      iconName="download"
                      color={
                        context === CONTRACT_CONTEXT ? 'secondary' : 'white'
                      }
                      style={{ fontSize: '30px', fontWeight: 'normal' }}
                    />
                  )
                }
                onClick={() => {
                  setLoading(true)
                  api
                    .downloadReportFile(downloadContext, applicationId)
                    .then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                      )
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute(
                        'download',
                        `${filenameSuffix}_${applicationId}_${regionSlug}.pdf`,
                      )
                      document.body.appendChild(link)
                      link.click()
                    })
                    .catch(() =>
                      confirm({
                        cancellationButtonProps: { style: { display: 'none' } },
                        content: (
                          <Alert severity="error">
                            <AlertTitle>
                              {t('General.somethingWentWrong')}
                            </AlertTitle>
                          </Alert>
                        ),
                        title: t('General.error'),
                      }),
                    )
                    .finally(() => setLoading(false))
                }}
              >
                {t('Views.FormSuccess.downloadButton')}
              </Button>
            )}
            {/* in case the user already has a membership, we hide the button in contract success page */}
            {((!hasMembershipStatus && context === CONTRACT_CONTEXT) ||
              context === MEMBERSHIP_CONTEXT) && (
              <Button
                data-test-id={TestIds.views.errorView.backToStartButton}
                variant="contained"
                color={context === CONTRACT_CONTEXT ? 'primary' : 'accent'}
                size="large"
                startIcon={
                  context === CONTRACT_CONTEXT ? (
                    <CustomIcon
                      iconName="mitgliedWerden"
                      color="white"
                      style={{ fontSize: '30px', fontWeight: 'normal' }}
                    />
                  ) : (
                    <CustomIcon
                      iconName="hzvTeilnahme"
                      color="textPrimary"
                      style={{ fontSize: '30px', fontWeight: 'normal' }}
                    />
                  )
                }
                component={RouterLink}
                to={() => region && handleClick(region)}
              >
                {context === CONTRACT_CONTEXT
                  ? t('Views.FormSuccess.becomeAMember')
                  : t('Views.FormSuccess.getHzvContract')}
              </Button>
            )}
          </Grid>
        </Grid>
      </ContentStripe>

      {/* What's next */}
      <ContentStripe className={classes.whatsNextContainer}>
        <Grid
          className={classes.whatsNextWrapper}
          container
          justifyContent="center"
          spacing={5}
        >
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {t('Views.FormSuccess.whatsNext')}
            </Typography>
          </Grid>
          {stepsList.map((step, index) => (
            <Grid
              item
              xs={10}
              md={context === CONTRACT_CONTEXT ? 2 : 3}
              key={step.icon}
            >
              <CustomIcon
                iconName={step.icon}
                color={context === CONTRACT_CONTEXT ? 'accent' : 'primary'}
                style={{ fontSize: '70px' }}
              />
              <Typography className={classes.whatsNextStep}>
                {index + 1}.
              </Typography>
              <Typography>{step.label}</Typography>
            </Grid>
          ))}
          {context === CONTRACT_CONTEXT && (
            <Grid item xs={12} md={8}>
              <Typography
                variant="subtitle1"
                className={classes.contractInformation}
              >
                {t('Views.FormSuccess.contractNextSteps.information')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </ContentStripe>

      {/* Newsletter */}
      <ContentStripe className={classes.newsletterContainer}>
        <Grid container justifyContent="center">
          <Grid container item justifyContent="center" sm={10} md={7}>
            <a href="https://haev.de/update" rel="noreferrer" target="_blank">
              <Image
                src={
                  smUp
                    ? '/static/images/newsletter-banner.png'
                    : '/static/images/newsletter-banner-mobile.png'
                }
                alt={t('Views.FormSuccess.newsletterBannerAlt')}
              />
            </a>
          </Grid>
        </Grid>
      </ContentStripe>

      {/* Contact Stripe */}
      <ContactStripe
        variant={context === CONTRACT_CONTEXT ? 'accent' : 'primary'}
      />
    </Page>
  )
}

export default FormSuccessView
