import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { format, sub } from 'date-fns'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'

import i18n from 'src/services/i18n/i18n'
import { PanelSubmitRow } from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow'
import { KeyboardDatePicker } from 'src/components/DatePicker/KeyboardDatePicker'

import { Region } from '../../../types'
import { ContractFormType } from '../ContractForm'
import {
  validateFirstName,
  validateLanrId,
  validateLastName,
} from '../validationSchema'

import useStyles from '../ContractForm.styles'

const today = new Date()
const maxDOB = format(sub(today, { years: 18 }), 'yyyy-MM-dd')
const minDOB = format(sub(today, { years: 100 }), 'yyyy-MM-dd')

export interface PanelPersonalDataType {
  title: string
  firstName: string
  lastName: string
  dateOfBirth: any
  lanrId: string
  haevgId: string
  efnId: string
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelPersonalDataType = {
  title: '',
  firstName: '',
  lastName: '',
  dateOfBirth: null, // Must be 'null' or the field is pre-filled with todays date.
  lanrId: '',
  haevgId: '',
  efnId: '',
}

export const validationSchema = Yup.object().shape({
  title: Yup.string().max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  firstName: validateFirstName,
  lastName: validateLastName,
  dateOfBirth: Yup.date()
    .typeError(i18n.t('ContractForm.Fields.PersonalData.DateOfBirth.invalid'))
    .nullable()
    .max(maxDOB, i18n.t('ContractForm.Fields.PersonalData.DateOfBirth.max'))
    .required(i18n.t('ContractForm.Fields.PersonalData.DateOfBirth.required')),
  lanrId: validateLanrId.required(
    i18n.t('ContractForm.Fields.PersonalData.LanrId.required'),
  ),
  efnId: Yup.string()
    .min(15, i18n.t('ContractForm.Fields.PersonalData.EfnId.min'))
    .max(15, i18n.t('ContractForm.Fields.PersonalData.EfnId.max'))
    .matches(/^\d+$/, i18n.t('ContractForm.Fields.PersonalData.EfnId.type')),
  haevgId: Yup.string()
    .max(9, i18n.t('ContractForm.Fields.PersonalData.HaevgId.max'))
    .matches(/^\d+$/, i18n.t('ContractForm.Fields.PersonalData.HaevgId.type')),
})

/* eslint-enable */

export interface PanelPersonalDataProps {
  className?: string
  formik: FormikProps<ContractFormType>
  region: Region | undefined
}

export const PanelPersonalData: FC<PanelPersonalDataProps> = ({
  className,
  formik,
  region,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(formik.touched.title && formik.errors.title)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.title && formik.errors.title}
            label={t('ContractForm.Fields.PersonalData.Title.label')}
            name="title"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.title}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <TextField
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            fullWidth
            inputProps={{ maxLength: 100 }}
            helperText={formik.touched.firstName && formik.errors.firstName}
            label={t('ContractForm.Fields.PersonalData.FirstName.label')}
            name="firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.firstName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <TextField
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            fullWidth
            inputProps={{ maxLength: 100 }}
            helperText={formik.touched.lastName && formik.errors.lastName}
            label={t('ContractForm.Fields.PersonalData.LastName.label')}
            name="lastName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.lastName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <KeyboardDatePicker
            error={Boolean(
              formik.touched.dateOfBirth && formik.errors.dateOfBirth,
            )}
            helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            label={t('ContractForm.Fields.PersonalData.DateOfBirth.label')}
            maxDate={maxDOB}
            minDate={minDOB}
            name="dateOfBirth"
            onBlur={formik.handleBlur}
            onDateChange={(value) => formik.setFieldValue('dateOfBirth', value)}
            value={formik.values.dateOfBirth}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.lanrId && formik.errors.lanrId)}
            fullWidth
            inputProps={{ maxLength: 9 }}
            helperText={
              <>
                {formik.touched.lanrId && formik.errors.lanrId}
                {region?.lanrIdHintForHzvContract && (
                  <Typography
                    color="textPrimary"
                    component="span"
                    style={{ fontSize: 'inherit' }}
                    variant="body1"
                  >
                    {region?.lanrIdHintForHzvContract}
                  </Typography>
                )}
              </>
            }
            label={t('ContractForm.Fields.PersonalData.LanrId.label')}
            name="lanrId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lanrId}
            required
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.haevgId && formik.errors.haevgId)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    arrow
                    enterTouchDelay={100}
                    title={
                      t('ContractForm.Fields.PersonalData.HaevgId.tooltip') ||
                      ''
                    }
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            inputProps={{ maxLength: 9 }}
            helperText={formik.touched.haevgId && formik.errors.haevgId}
            label={t('ContractForm.Fields.PersonalData.HaevgId.label')}
            name="haevgId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.haevgId}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.efnId && formik.errors.efnId)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    arrow
                    enterTouchDelay={100}
                    title={
                      t('ContractForm.Fields.PersonalData.EfnId.tooltip') || ''
                    }
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            inputProps={{ maxLength: 15 }}
            helperText={
              <>
                {formik.touched.efnId && formik.errors.efnId}
                <Typography
                  color="textPrimary"
                  component="span"
                  style={{ fontSize: 'inherit' }}
                  variant="body1"
                >
                  {t('ContractForm.Fields.PersonalData.EfnId.hint')}
                </Typography>
              </>
            }
            label={t('ContractForm.Fields.PersonalData.EfnId.label')}
            name="efnId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.efnId}
            variant="outlined"
          />
        </Grid>

        <PanelSubmitRow />
      </Grid>
    </fieldset>
  )
}

export default PanelPersonalData
