import { makeStyles } from '@material-ui/core/styles'

import { Theme } from 'src/theme'

const useStyles = makeStyles((theme: Theme) => ({
  tabsContainer: {
    width: '100%',
  },

  tabsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '-3px',
  },

  tabRoot: {
    opacity: 1,
    maxWidth: 'unset',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold as number,
    borderTopLeftRadius: theme.borderRadius.medium,
    borderTopRightRadius: theme.borderRadius.medium,
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0.5),

    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      width: theme.spacing(45),
      margin: theme.spacing(0, 1.25),
    },
  },

  tabLeft: {
    backgroundColor: theme.palette.primary.light,
    border: `3px solid ${theme.palette.primary.main}`,
    borderBottom: 'none',
  },

  tabRight: {
    backgroundColor: theme.palette.accent.light,
    border: `3px solid ${theme.palette.accent.main}`,
    borderBottom: 'none',
  },

  tabInactive: {
    marginBottom: '3px',
  },

  tabPanel: {
    borderTop: '3px solid',
  },

  leftPanel: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
  },

  rightPanel: {
    backgroundColor: theme.palette.accent.light,
    borderColor: theme.palette.accent.main,
  },

  contactCardsContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10),
    },
  },

  contactCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacing(5),
  },

  phoneNumber: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
  },

  moreContactsButton: {
    margin: theme.spacing(5, 'auto', 0),
  },

  serviceHours: {
    whiteSpace: 'pre-line',
  },
}))

export default useStyles
