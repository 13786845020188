import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import Button from 'src/components/Button/Button'

import useStyles from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow.styles'

export const PanelSubmitRow: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid
      container
      item
      justifyContent="flex-end"
      xs={12}
      className={classes.root}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography>
            {t('ContractForm.mandatoryFieldsAreMarkedWithAsterisk')}
          </Typography>
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" size="small">
            {t('ContractForm.next')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
