import type { FC } from 'react'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'

import { withHttps } from 'src/utils'
import { multipleSelections, singleSelection } from 'src/utils/cacheValidation'
import i18n from 'src/services/i18n/i18n'
import Button from 'src/components/Button/Button'
import BonusData from 'src/components/MembershipForm/PanelCheckData/BonusData'
import { SurveyComponent } from 'src/components/SurveyComponent'

import { MembershipFormType } from '../MembershipForm'

import PtqzData from './PtqzData'

import useStyles from '../MembershipForm.styles'

export interface PanelCheckDataType {
  privacyPolicyConsent: boolean
  statuteConsent: boolean
  hzvNews: boolean
  survey: string
  toggleOtherSurveyOption: boolean
  otherSurveyOption: string
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelCheckDataType = {
  privacyPolicyConsent: false,
  hzvNews: false,
  statuteConsent: false,
  survey: '',
  toggleOtherSurveyOption: false,
  otherSurveyOption: '',
}

export const validationSchema = (
  membershipBonus: string[],
  surveyOptions: string[],
) =>
  Yup.object().shape({
    isPtqzInterested: Yup.boolean(),
    isPtqzModerator: Yup.boolean(),
    selectedBonus: Yup.string().transform((current) =>
      singleSelection(membershipBonus, current),
    ),
    survey: Yup.array()
      .of(Yup.string())
      .transform((current) => multipleSelections(surveyOptions, current)),
    statuteConsent: Yup.boolean().oneOf(
      [true],
      i18n.t('MembershipForm.Fields.CheckData.StatuteConsent.required'),
    ),
    privacyPolicyConsent: Yup.boolean().oneOf(
      [true],
      i18n.t('MembershipForm.Fields.CheckData.PrivacyPolicyConsent.required'),
    ),
    otherSurveyOption: Yup.string().when('toggleOtherSurveyOption', {
      is: true,
      then: Yup.string().max(
        1000,
        i18n.t('General.Form.maxLength', { length: 1000 }),
      ),
    }),
  })
/* eslint-enable */

export interface PanelPanelCheckDataProps {
  className?: string
  formik: FormikProps<MembershipFormType>
  ptqzMembershipActive: boolean | undefined
  membershipBonus: string[] | undefined
  regionalAssociationLabel: string | undefined
  regionalAssociationLabelCheckout: string | null | undefined
  surveyOptions?: string[]
  statuteUrl: string | undefined
  privacyPolicyUrl: string | undefined
}

export const PanelPanelCheckData: FC<PanelPanelCheckDataProps> = ({
  className,
  formik,
  ptqzMembershipActive,
  membershipBonus,
  regionalAssociationLabel,
  regionalAssociationLabelCheckout,
  surveyOptions = [],
  statuteUrl,
  privacyPolicyUrl,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const showSurveyOptions = !!surveyOptions?.length
  const showMembershipBonus = !!membershipBonus?.length

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={1}>
        {ptqzMembershipActive && (
          <Grid item xs={12}>
            <PtqzData formik={formik} />
          </Grid>
        )}
        {showMembershipBonus && (
          <Grid item xs={12}>
            <BonusData formik={formik} membershipBonusList={membershipBonus} />
          </Grid>
        )}
        {showSurveyOptions && (
          <SurveyComponent formik={formik} surveyOptions={surveyOptions} />
        )}
        <Grid item xs={12}>
          <FormControl
            error={Boolean(
              formik.touched.statuteConsent && formik.errors.statuteConsent,
            )}
            required
            variant="outlined"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.statuteConsent}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="statuteConsent"
                  />
                }
                label={
                  <Trans
                    i18nKey={
                      'MembershipForm.Fields.CheckData.StatuteConsent.label'
                    }
                    values={{
                      associationLabel: regionalAssociationLabelCheckout
                        ? regionalAssociationLabelCheckout
                        : regionalAssociationLabel,
                    }}
                    components={{
                      linkTag: (
                        <Link
                          rel="noreferrer"
                          target="_blank"
                          className="text-primary"
                          to={{
                            pathname: statuteUrl ? withHttps(statuteUrl) : '#',
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            </FormGroup>

            {formik.touched.statuteConsent && formik.errors.statuteConsent && (
              <FormHelperText>{formik.errors.statuteConsent}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={Boolean(
              formik.touched.privacyPolicyConsent &&
                formik.errors.privacyPolicyConsent,
            )}
            required
            variant="outlined"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.privacyPolicyConsent}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="privacyPolicyConsent"
                  />
                }
                label={
                  <Trans
                    i18nKey={
                      'MembershipForm.Fields.CheckData.PrivacyPolicyConsent.label'
                    }
                    components={{
                      linkTag: (
                        <Link
                          rel="noreferrer"
                          target="_blank"
                          className="text-primary"
                          to={{
                            pathname: privacyPolicyUrl
                              ? withHttps(privacyPolicyUrl)
                              : '#',
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            </FormGroup>

            {formik.touched.privacyPolicyConsent &&
              formik.errors.privacyPolicyConsent && (
                <FormHelperText>
                  {formik.errors.privacyPolicyConsent}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={Boolean(formik.touched.hzvNews && formik.errors.hzvNews)}
            required
            variant="outlined"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.hzvNews}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="hzvNews"
                  />
                }
                label={t('MembershipForm.Fields.CheckData.HzvNews.label')}
              />
            </FormGroup>

            {formik.touched.hzvNews && formik.errors.hzvNews && (
              <FormHelperText>{formik.errors.hzvNews}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {t('MembershipForm.legalInformation', {
              associationLabel: regionalAssociationLabelCheckout
                ? regionalAssociationLabelCheckout
                : regionalAssociationLabel,
            })}
          </Typography>
        </Grid>

        <Grid container item justifyContent="flex-end" xs={12}>
          <Grid item>
            <Button
              disabled={formik.isSubmitting}
              size="small"
              endIcon={formik.isSubmitting && <CircularProgress size={20} />}
              type="submit"
              variant="contained"
            >
              {t('MembershipForm.applyMembership')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </fieldset>
  )
}

export default PanelPanelCheckData
