import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    formControlLabelConcern: {
      marginRight: theme.spacing(3),
    },
  }),

  { name: 'ContactForm' },
)

export default useStyles
