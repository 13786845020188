import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      width: '100%',
    },

    backgroundDefault: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },

    backgroundPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    backgroundPrimaryLight: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },

    backgroundSecondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },

    backgroundAccent: {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.accent.contrastText,
    },

    backgroundAccentLight: {
      backgroundColor: theme.palette.accent.light,
      color: theme.palette.accent.contrastText,
    },

    backgroundFog: {
      backgroundColor: theme.palette.fog.main,
      color: theme.palette.fog.contrastText,
    },

    backgroundImage: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
    },

    container: {
      position: 'relative',
      display: 'flex',
      padding: theme.spacing(0, 2.5),
    },

    fullHeight: {
      minHeight: `calc(100vh - ${theme.settings.topBarHeight}px)`,
      display: 'flex',
    },

    image: {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),

  { name: 'ContentStripe' },
)

export default useStyles
