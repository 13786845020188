import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    preventTransform: {
      position: 'relative',
      transform: 'none',
      transformOrigin: 'unset',
    },

    badge: {
      borderRadius: '50%',
      fontWeight: 'bold',
    },

    colorSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },

    colorAccent: {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.accent.contrastText,
    },

    // The outlined variant needs to be wider and higher than the regular variant in its default size.
    // In order to be properly overridden by the different size variants though, the size variant styles
    // have to be defined after this one.
    variantOutlined: {
      backgroundColor: theme.palette.background.paper,
      minWidth: 24,
      height: 24,
      border: '2px solid',

      '&[class*="colorPrimary"]': {
        color: theme.palette.primary.main,
      },
      '&[class*="colorSecondary"]': {
        color: theme.palette.secondary.main,
      },
      '&[class*="colorError"]': {
        color: theme.palette.error.main,
      },
      // This selection differs from the rest as it is a custom color class defined in this component.
      '&$colorAccent': {
        color: theme.palette.accent.main,
      },
      '&$colorSuccess': {
        color: theme.palette.success.main,
      },
    },

    // Define size variant styles after 'variantOutlined' to properly
    // override its 'minWidth' and 'height' properties.
    sizeSmall: {
      fontSize: 16,
      minWidth: 35,
      height: 35,
    },

    sizeMedium: {
      fontSize: 18,
      minWidth: 45,
      height: 45,
    },

    sizeLarge: {
      fontSize: 22,
      minWidth: 60,
      height: 60,
    },
  }),

  { name: 'Badge' },
)

export default useStyles
