import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from '@material-ui/lab/Skeleton'

import { TestIds } from 'src/testIds'
import { getImprint, Imprint } from 'src/services/api/api'
import { LegalDocument } from 'src/components/LegalDocument/LegalDocument'
import Markdown from 'src/components/Markdown/Markdown'
import Page from 'src/components/Page/Page'

import useStyles from 'src/components/Markdown/Markdown.styles'

const LegalNoticeTestIds = TestIds.views.legalNotice

const LegalNoticeView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [imprint, setImprint] = useState<Imprint | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: _imprint } = await getImprint()
        setImprint(_imprint)
      } catch (error) {
        setImprint(null)
      }
    }
    fetchData()
  }, [t])

  return (
    <Page
      data-test-id={LegalNoticeTestIds.pageWrapper}
      title={t('Views.LegalNotice.title')}
    >
      <LegalDocument title={t('Views.LegalNotice.title')}>
        {!imprint ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          <Markdown className={classes.textPageStyles}>
            {imprint.imprintText}
          </Markdown>
        )}
      </LegalDocument>
    </Page>
  )
}

export default LegalNoticeView
