import type { ChangeEvent, FC } from 'react'
import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import type { Contract, Region, SpecialContract } from 'src/types'
import useContracts from 'src/hooks/useContracts'
import { AspectRatioImg } from 'src/components/AspectRatioImg'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import ContractCard from 'src/components/ContractCard/ContractCard'
import InsuranceFilter from 'src/components/InsuranceFilter/InsuranceFilter'

import SpecialContractCard from '../ContractCard/SpecialContractCard'

import useStyles from './ContractList.styles'

const ComponentTestIds = TestIds.components

const contractsSort = (
  contractA: Pick<Contract, 'abbreviation'>,
  contractB: Pick<Contract, 'abbreviation'>,
) =>
  contractA.abbreviation.localeCompare(contractB.abbreviation, undefined, {
    sensitivity: 'base',
  })

export interface ContractListProps {
  currentRegion: Region | null | undefined
}

export const ContractList: FC<ContractListProps> = ({
  currentRegion,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    actions: contractActions,
    getters: contractGetters,
    state: contractState,
  } = useContracts()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  })

  const selectedContracts = contractState.contractList.selectedContracts
  const canApplyForFilteredContracts =
    contractState.contractList.canApplyForFilteredContracts
  const alreadyAppliedForFilteredContracts =
    contractState.contractList.alreadyAppliedForFilteredContracts
  const currentRegionSlug = contractState.contractList.currentRegion ?? ''

  const regionalContracts = contractGetters.getContracts({
    by: 'regionSlug',
    value: currentRegionSlug,
  })
  const specialContracts = currentRegion?.specialContracts
  const subsetOfSelectedAndFilteredContracts =
    contractGetters.getSubSetOfSelectedAndFilteredContracts()
  const allContractsSelected = canApplyForFilteredContracts.length
    ? canApplyForFilteredContracts.length ===
      subsetOfSelectedAndFilteredContracts.length
    : false

  const handleSelectAllContracts = () => {
    if (!canApplyForFilteredContracts) return

    if (allContractsSelected) {
      // Same length probably means all contracts are selected already. Thus toggle the selection and remove all.
      contractActions.setSelectedContracts([])
    } else {
      // Some contracts are not selected. Just iterate over all available contracts and return their ids to the list.
      contractActions.setSelectedContracts(
        canApplyForFilteredContracts.map((contract: Contract) => contract.id),
      )
    }
  }

  // Select all Contracts for the corresponding region if there are no contracts pre-selected.
  useEffect(() => {
    if (!selectedContracts.length) {
      handleSelectAllContracts()
    }
  }, [currentRegion]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectContract = (event: ChangeEvent<HTMLInputElement>) => {
    const contractId = parseInt(event.target.value)

    contractActions.setSelectedContract(contractId)
  }

  return (
    <Fragment {...props}>
      <ContentStripe className={classes.headerRoot}>
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          spacing={1}
          direction={'column'}
        >
          {currentRegion?.regionalAssociationLogoUrl && (
            <Grid item className={classes.logoGridWrapper}>
              <AspectRatioImg
                src={currentRegion.regionalAssociationLogoUrl}
                alt={'Landesverband logo'}
              />
            </Grid>
          )}
        </Grid>
      </ContentStripe>

      <ContentStripe>
        <Grid
          spacing={5}
          container
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid
            container
            item
            justifyContent="center"
            alignItems="stretch"
            classes={{ root: classes.contractsGrid }}
            direction={mdUp ? 'row' : 'column-reverse'}
            wrap={mdUp ? 'wrap' : 'nowrap'}
          >
            <Grid
              item
              xs={12}
              md={6}
              container
              justifyContent={mdUp ? 'flex-start' : 'center'}
            >
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={canApplyForFilteredContracts.length === 0}
                      checked={allContractsSelected}
                      data-test-id={
                        ComponentTestIds.contractList.selectAllCheckbox
                      }
                      onChange={handleSelectAllContracts}
                    />
                  }
                  label={
                    <Box className={clsx(classes.bold)}>
                      <Typography
                        variant="subtitle1"
                        data-test-id={
                          ComponentTestIds.contractList.selectAllCheckboxLabel
                        }
                        component="span"
                      >
                        {t('ContractList.chooseAllContracts', {
                          all: regionalContracts?.length,
                          count: canApplyForFilteredContracts?.length,
                        })}
                      </Typography>
                    </Box>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box marginBottom={mdUp ? 0 : 5}>
                <InsuranceFilter
                  insuranceCompanies={
                    currentRegion?.participatingInsuranceCompanies || ['']
                  }
                />
              </Box>
            </Grid>
          </Grid>
          {canApplyForFilteredContracts
            .sort(contractsSort)
            .map((contract: Contract) => {
              const checked = !!selectedContracts.find(
                (contractId) => contractId === contract.id,
              )
              return (
                <Grid
                  key={contract.id}
                  item
                  xs={12}
                  className={classes.contractsGridItemRoot}
                >
                  <ContractCard
                    checked={checked}
                    showBorder={checked}
                    contract={contract}
                    data-test-id={ComponentTestIds.contractCard.cardWrapper}
                    onChange={handleSelectContract}
                    regionName={currentRegion?.name}
                  />
                </Grid>
              )
            })}
          {!!alreadyAppliedForFilteredContracts.length && (
            <>
              {alreadyAppliedForFilteredContracts
                .sort(contractsSort)
                .map((contract: Contract) => {
                  const checked = !!selectedContracts.find(
                    (contractId) => contractId === contract.id,
                  )
                  return (
                    <Grid
                      key={contract.id}
                      item
                      xs={12}
                      className={classes.contractsGridItemRoot}
                    >
                      <ContractCard
                        disabled={true}
                        checked={checked}
                        contract={contract}
                        data-test-id={ComponentTestIds.contractCard.cardWrapper}
                        onChange={handleSelectContract}
                        regionName={currentRegion?.name}
                      />
                    </Grid>
                  )
                })}
            </>
          )}

          {specialContracts && !!specialContracts.length && (
            <Grid item className={classes.contractsGridItemRoot}>
              <Box component="section" marginTop={10} paddingBottom={5}>
                <Typography
                  variant="h3"
                  component="h2"
                  className={classes.sectionTitle}
                >
                  {t('ContractList.specialContractsTitle')}
                </Typography>
                <Box marginTop={5}>
                  {specialContracts
                    .sort(contractsSort)
                    .map((specialContract: SpecialContract) => {
                      return (
                        <Grid key={specialContract.id} item xs={12}>
                          <SpecialContractCard
                            data-test-id={
                              ComponentTestIds.specialContractCard.cardWrapper
                            }
                            specialContract={specialContract}
                            regionName={currentRegion?.name}
                          />
                        </Grid>
                      )
                    })}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </ContentStripe>
    </Fragment>
  )
}

export default ContractList
