import type { ChangeEvent, FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import { Contract } from 'src/types'
import Button from 'src/components/Button/Button'
import ContractCardDialog from 'src/components/ContractCardDialog/ContractCardDialog'
import CustomIcon from 'src/components/CustomIcon/CustomIcon'
import GradientBorderWrapper from 'src/components/GradientBorderWrapper/GradientBorderWrapper'
import Image from 'src/components/Image/Image'

import useStyles from './ContractCard.styles'

const ComponentTestIds = TestIds.components
export interface ContractCardProps {
  checked: boolean | undefined
  contract: Contract
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  regionName: string | undefined
  showBorder?: boolean
  disabled?: boolean
}

/**
 * This Card Component has a mobile and a desktop version.
 * The views differ too much from each other, so that 2 variants are necessary.
 */
export const ContractCard: FC<ContractCardProps> = ({
  checked,
  contract,
  onChange,
  disabled = false,
  regionName,
  showBorder = false,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  const [openModalWith, setOpenModalWith] = useState<
    'details' | 'insurance' | 'software' | undefined
  >()

  const Img = (
    <Image
      className={clsx(classes.cardImg)}
      src={contract.logoUrl || ''}
      alt={contract.abbreviation}
    />
  )

  const ButtonGroup = (
    <Grid spacing={1} container justifyContent={smUp ? 'flex-start' : 'center'}>
      <Grid item xs="auto">
        <Button
          data-test-id={ComponentTestIds.contractCard.dialogDetailsButton}
          color="accent"
          size="small"
          variant="contained"
          onClick={() => setOpenModalWith('details')}
        >
          {t('ContractCard.buttonDetails')}
        </Button>
      </Grid>

      <Grid item xs="auto">
        <Button
          data-test-id={ComponentTestIds.contractCard.dialogInsuranceButton}
          color="accent"
          size="small"
          variant="contained"
          onClick={() => setOpenModalWith('insurance')}
        >
          {t('ContractCard.buttonInsurance')}
        </Button>
      </Grid>

      <Grid item xs="auto">
        <Button
          data-test-id={ComponentTestIds.contractCard.dialogSoftwareButton}
          color="accent"
          size="small"
          variant="contained"
          onClick={() => setOpenModalWith('software')}
        >
          {t('ContractCard.buttonSoftware')}
        </Button>
      </Grid>
    </Grid>
  )

  function renderContractTitle(contract: Contract) {
    return <Typography variant="h3">{contract.abbreviation}</Typography>
  }

  return (
    <GradientBorderWrapper
      transparent={!showBorder}
      hideOverflow
      className={clsx(classes.gradientBorderWrapperRoot)}
    >
      <Card
        className={clsx({
          [classes.disabled]: disabled,
        })}
        {...props}
        elevation={10}
      >
        <Hidden xsDown>
          <CardContent
            component={Grid}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container direction="column" item sm={10} spacing={3}>
              <Grid item>
                {disabled ? (
                  <Box display="flex" alignItems="center">
                    <CustomIcon
                      iconName="zweiHaken"
                      color="accent"
                      style={{
                        fontSize: '22px',
                        marginRight: theme.spacing(1),
                      }}
                    />
                    {renderContractTitle(contract)}
                  </Box>
                ) : (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          name={contract.abbreviation}
                          onChange={onChange}
                          value={contract.id}
                        />
                      }
                      label={renderContractTitle(contract)}
                    />
                  </FormGroup>
                )}
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {disabled ? 'Sie nehmen bereits teil.' : contract.teaserText}
                </Typography>
              </Grid>
              <Grid item>{ButtonGroup} </Grid>
            </Grid>

            <Grid item sm={2}>
              {Img}
            </Grid>
          </CardContent>
        </Hidden>

        <Hidden smUp>
          <CardContent className={classes.cardContentMobileRoot}>
            <Grid container spacing={2} direction="column">
              <Grid item xs>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    {disabled ? (
                      <Box display="flex" alignItems="center">
                        <CustomIcon
                          iconName="zweiHaken"
                          color="accent"
                          style={{
                            fontSize: '22px',
                            marginRight: theme.spacing(1),
                          }}
                        />
                        {renderContractTitle(contract)}
                      </Box>
                    ) : (
                      <Typography variant="h3" component="h2">
                        {contract.abbreviation}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {!disabled && (
                      <Switch
                        className={clsx(classes.switchRoot)}
                        disabled={disabled}
                        checked={checked}
                        name={contract.abbreviation}
                        onChange={onChange}
                        value={contract.id}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent={disabled ? 'flex-start' : 'center'}
                >
                  <Grid item>
                    {disabled ? (
                      <Typography variant="subtitle2">
                        Sie nehmen bereits teil.
                      </Typography>
                    ) : (
                      Img
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>{ButtonGroup}</CardActions>
        </Hidden>

        <ContractCardDialog
          data-test-id={TestIds.components.contractDialog}
          contract={contract}
          contractLogo={Img}
          open={!!openModalWith}
          openModalWith={openModalWith}
          onClose={() => setOpenModalWith(undefined)}
          regionName={regionName}
        />
      </Card>
    </GradientBorderWrapper>
  )
}

export default ContractCard
