import type { FC } from 'react'
import React from 'react'
import type { BadgeProps as MUIBadgeProps } from '@material-ui/core'
import { Badge as MUIBadge } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './Badge.styles'

export interface BadgeProps extends Omit<MUIBadgeProps, 'color' | 'variant'> {
  color?: 'primary' | 'secondary' | 'accent' | 'default' | 'error' | 'success'
  size?: 'small' | 'medium' | 'large'
  variant?: 'dot' | 'standard' | 'outlined'
}

export const Badge: FC<BadgeProps> = ({
  children,
  className,
  color,
  size,
  variant,
  // Prevent warning:
  // Failed prop type: Material-UI: `overlap="rectangle"` was deprecated.
  // Use `overlap="rectangular"` instead.
  overlap = 'rectangular',
  ...props
}) => {
  const classes = useStyles()
  const muiColorProp =
    color === 'success' || color === 'accent' ? undefined : color
  const muiVariantProp = variant === 'outlined' ? undefined : variant

  return (
    <MUIBadge
      classes={{
        badge: clsx({
          [classes.badge]: true,
          [classes.preventTransform]: !children,
          [classes.colorAccent]: color === 'accent',
          [classes.colorSuccess]: color === 'success',
          [classes.sizeSmall]: size === 'small',
          [classes.sizeMedium]: size === 'medium',
          [classes.sizeLarge]: size === 'large',
          [classes.variantOutlined]: variant === 'outlined',
        }),
      }}
      className={clsx(className)}
      color={muiColorProp}
      variant={muiVariantProp}
      overlap={overlap}
      {...props}
    >
      {children}
    </MUIBadge>
  )
}

export default Badge
