import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    contractFormWrapper: {
      '&.MuiGrid-item': {
        marginBottom: theme.spacing(12.5),

        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    bold: { fontWeight: theme.typography.fontWeightBold as number },
  }),

  { name: 'MembershipFormView' },
)

export default useStyles
