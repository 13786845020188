import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import {
  Box,
  ClickAwayListener,
  Divider,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import type { Theme } from 'src/theme'
import type { Region } from 'src/types'
import { RegionStatus } from 'src/types'
import { withHttps } from 'src/utils'
import { getRegionNameForMembership } from 'src/utils/region'
import useRegions from 'src/hooks/useRegions'
import Button from 'src/components/Button/Button'

import useStyles from './RegionSelect.styles'

const ComponentTestIds = TestIds.components
export interface RegionSelectOption {
  name: string
  okv: string
  slug: string
  status: 'active' | 'passive'
  url: string | null
}

export interface RegionSelectProps {
  buttonText?: string
  className?: string
  context: 'contracts' | 'membership'
  inline?: boolean
  isAccent?: boolean
}

export const RegionSelect: FC<RegionSelectProps> = ({
  buttonText,
  className,
  context,
  inline,
  isAccent = false,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const history = useHistory()
  const { state: regionState } = useRegions()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [selectedRegionOption, setSelectedRegionOption] =
    useState<RegionSelectOption | null>(null)
  const downSm = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  })
  const hideButtonText = inline && downSm
  const loading = regionState.loading
  const regions = regionState.regions

  /**
   * Maps regions to usable select options, removes inactive regions, groups them by
   * their contextual status and sorts them by name.
   */
  const mapRegions = (_regions: Region[]) => {
    return (
      _regions
        // Remove inactive regions.
        .filter((region) =>
          context === 'contracts'
            ? region.contractStatus !== RegionStatus.INACTIVE
            : region.membershipStatus !== RegionStatus.INACTIVE,
        )
        // Map to usable options for the AutoComplete Component.
        .map(
          (region): RegionSelectOption => ({
            name:
              context === 'contracts'
                ? region.name
                : getRegionNameForMembership(region),
            okv: region.okv,
            slug: region.slug,
            status:
              context === 'contracts'
                ? region.contractStatus === RegionStatus.ACTIVE
                  ? 'active'
                  : 'passive'
                : region.membershipStatus === RegionStatus.ACTIVE
                ? 'active'
                : 'passive',
            url:
              context === 'contracts'
                ? region.contractUrl
                : region.membershipUrl,
          }),
        )
        // Group by status first and then sort alphabetically.
        .sort(
          (regionOptionA, regionOptionB) =>
            regionOptionA.status.localeCompare(regionOptionB.status) ||
            regionOptionA.name.localeCompare(regionOptionB.name),
        )
    )
  }

  /**
   * Redirect to the region.
   */
  const handleSubmit = () => {
    if (!selectedRegionOption) return setShowTooltip(true)

    let redirectPath = generatePath(routes.errorPage.path, {
      errorCode: 404,
    })

    // Redirect to external website.
    if (selectedRegionOption.status === 'passive') {
      return (window.location.href = withHttps(selectedRegionOption.url || ''))
    }

    // Redirect to ContractListView with appropriate slug.
    if (selectedRegionOption.status === 'active' && context === 'contracts') {
      redirectPath = generatePath(
        routes.root.routes!.contracts.routes!.contractList.path,
        {
          regionSlug: selectedRegionOption.slug,
        },
      )
    }

    // Redirect to MembershipFormView with appropriate slug.
    if (selectedRegionOption.status === 'active' && context === 'membership') {
      redirectPath = generatePath(
        routes.root.routes!.membership.routes!.membershipForm.path,
        {
          regionSlug: selectedRegionOption.slug,
        },
      )
    }

    return history.push(redirectPath)
  }

  return (
    <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
      <Box
        className={clsx(classes.root, inline && classes.inline, className)}
        data-test-id={ComponentTestIds.regionSelect.wrapper}
        {...props}
      >
        <Tooltip
          arrow
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={() => setShowTooltip(false)}
          open={showTooltip}
          placement="top"
          title={
            <Typography align="center">{t('RegionSelect.required')}</Typography>
          }
        >
          <Autocomplete
            classes={{
              inputRoot: classes.inputRoot,
              listbox: classes.listbox,
              paper: classes.paper,
            }}
            className={clsx(classes.selectInput)}
            getOptionLabel={(regionOption) => regionOption.name}
            getOptionSelected={(option, value) => option.okv === value.okv}
            groupBy={(regionOption) => regionOption.status}
            loading={loading}
            noOptionsText={t('RegionSelect.noOptions')}
            onChange={(_, regionOption) =>
              setSelectedRegionOption(regionOption)
            }
            onOpen={() => setShowTooltip(false)}
            options={mapRegions(regions)}
            renderGroup={(params) => [
              params.group === 'passive' && <Divider key={params.key} />,
              params.children,
            ]}
            renderInput={(params) => (
              <TextField
                className={classes.textField}
                name="region"
                placeholder={t('RegionSelect.placeholder')}
                variant="outlined"
                {...params}
              />
            )}
            renderOption={(regionOption) => (
              <span
                className={clsx({
                  [classes.passive]: regionOption.status === 'passive',
                })}
                data-test-id={
                  regionOption.status === 'passive'
                    ? `${ComponentTestIds.regionSelect.option} ${ComponentTestIds.regionSelect.optionPassive}`
                    : ComponentTestIds.regionSelect.option
                }
              >
                {regionOption.name}
              </span>
            )}
            value={selectedRegionOption}
          />
        </Tooltip>

        <Button
          className={classes.selectButton}
          color={isAccent ? 'accent' : 'primary'}
          data-test-id={ComponentTestIds.regionSelect.submitButton}
          disableElevation
          onClick={handleSubmit}
          size="large"
          variant="contained"
        >
          {hideButtonText ? (
            <ChevronRightIcon />
          ) : buttonText ? (
            buttonText
          ) : (
            t('RegionSelect.placeholder')
          )}
        </Button>
      </Box>
    </ClickAwayListener>
  )
}

export default RegionSelect
