import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from '@material-ui/lab/Skeleton'

import { TestIds } from 'src/testIds'
import { getGtc, Gtc } from 'src/services/api/api'
import { LegalDocument } from 'src/components/LegalDocument/LegalDocument'
import Markdown from 'src/components/Markdown/Markdown'
import Page from 'src/components/Page/Page'

import useStyles from 'src/components/Markdown/Markdown.styles'

const GtcTestIds = TestIds.views.gtc

const GtcView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [gtc, setGtc] = useState<Gtc | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: _gtc } = await getGtc()
        setGtc(_gtc)
      } catch (error) {
        setGtc(null)
      }
    }
    fetchData()
  }, [t])

  return (
    <Page data-test-id={GtcTestIds.pageWrapper} title={t('Views.Gtc.title')}>
      <LegalDocument title={t('Views.Gtc.titleFull')}>
        {!gtc ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          <Markdown className={classes.textPageStyles}>{gtc.gtcText}</Markdown>
        )}
      </LegalDocument>
    </Page>
  )
}

export default GtcView
