import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(10, 0),
    },

    contactInfosContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(5),
      textAlign: 'center',
      alignItems: 'center',
    },

    phoneIcon: {
      fontSize: '22px',
      marginRight: theme.spacing(1),
    },

    phoneLink: {
      color: 'inherit',
      textDecoration: 'none',
    },
  }),

  { name: 'ContactStripe' },
)

export default useStyles
