import type { ComponentProps, FC } from 'react'
import React from 'react'
import clsx from 'clsx'

import useStyles from './CustomIcon.styles'

export type CustomIconsNameProp =
  | 'interessenvertretung'
  | 'verwaltungskosten'
  | 'vergünstigungen'
  | 'versorgung'
  | 'sicherheit'
  | 'buerokratie'
  | 'region'
  | 'vertraegeAuswaehlen'
  | 'onlineBeantragen'
  | 'download'
  | 'hzvTeilnahme'
  | 'mitgliedWerden'
  | 'user'
  | 'telefon'
  | 'briefOffen'
  | 'zweiHaken'
  | 'checkListe'
  | 'brief'
  | 'daumen'
  | 'haken'
  | 'klemmbrett'
  | 'edelstein'

export interface CustomIconsProps extends ComponentProps<'span'> {
  color?: 'primary' | 'secondary' | 'textPrimary' | 'white' | 'accent'
  iconName: CustomIconsNameProp
}

export const CustomIcon: FC<CustomIconsProps> = ({
  color,
  iconName,
  ...props
}) => {
  const classes = useStyles()
  let icon
  let iconColor

  switch (iconName) {
    case 'interessenvertretung':
      icon = <span className={classes.fontello}>&#xe80c;</span>
      break
    case 'verwaltungskosten':
      icon = <span className={classes.fontello}>&#xe810;</span>
      break
    case 'vergünstigungen':
      icon = <span className={classes.fontello}>&#xe812;</span>
      break
    case 'versorgung':
      icon = <span className={classes.fontello}>&#xe813;</span>
      break
    case 'sicherheit':
      icon = <span className={classes.fontello}>&#xe80d;</span>
      break
    case 'buerokratie':
      icon = <span className={classes.fontello}>&#xe817;</span>
      break
    case 'region':
      icon = <span className={classes.fontello}>&#xe818;</span>
      break
    case 'vertraegeAuswaehlen':
      icon = <span className={classes.fontello}>&#xe814;</span>
      break
    case 'onlineBeantragen':
      icon = <span className={classes.fontello}>&#xe815;</span>
      break
    case 'download':
      icon = <span className={classes.fontelloRedesign}>&#xe900;</span>
      break
    case 'hzvTeilnahme':
      icon = <span className={classes.fontelloRedesign}>&#xe901;</span>
      break
    case 'mitgliedWerden':
      icon = <span className={classes.fontelloRedesign}>&#xe902;</span>
      break
    case 'user':
      icon = <span className={classes.fontelloRedesign}>&#xe903;</span>
      break
    case 'telefon':
      icon = <span className={classes.fontelloRedesign}>&#xe904;</span>
      break
    case 'briefOffen':
      icon = <span className={classes.fontelloRedesign}>&#xe905;</span>
      break
    case 'zweiHaken':
      icon = <span className={classes.fontelloRedesign}>&#xe906;</span>
      break
    case 'checkListe':
      icon = <span className={classes.fontelloRedesign}>&#xe907;</span>
      break
    case 'brief':
      icon = <span className={classes.fontelloRedesign}>&#xe908;</span>
      break
    case 'daumen':
      icon = <span className={classes.fontelloRedesign}>&#xe909;</span>
      break
    case 'haken':
      icon = <span className={classes.fontelloRedesign}>&#xe90A;</span>
      break
    case 'klemmbrett':
      icon = <span className={classes.fontelloRedesign}>&#xe90B;</span>
      break
    case 'edelstein':
      icon = <span className={classes.fontelloRedesign}>&#xe90C;</span>
      break
    default:
      icon = <span></span>
  }

  switch (color) {
    case 'primary':
      iconColor = classes.primary
      break
    case 'secondary':
      iconColor = classes.secondary
      break
    case 'textPrimary':
      iconColor = classes.textPrimary
      break
    case 'white':
      iconColor = classes.white
      break
    case 'accent':
      iconColor = classes.accent
      break
    default:
      iconColor = classes.primary
  }

  return (
    <span
      className={clsx({
        [classes.root]: true,
        [iconColor]: !!color,
      })}
      {...props}
    >
      {icon}
    </span>
  )
}

export default CustomIcon
