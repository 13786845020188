export const TestIds = {
  components: {
    certificateStripe: 'components-certificateStripe',
    contactStripe: 'components-contactStripe',

    contractForm: {
      wrapper: 'components-contractForm-wrapper',
      panel: 'components-contractForm-panel',
    },

    membershipForm: {
      wrapper: 'components-membershipForm-wrapper',
      panel: 'components-membershipForm-panel',
    },

    contactForm: {
      wrapper: 'components-contactForm-wrapper',
      submitButton: 'components-contactForm-submitButton',
    },

    errorDialog: {
      wrapper: 'components-errorDialog-wrapper',
    },

    contractList: {
      numberOfRegionalContracts: 'components-contractList-numberOfContracts',
      selectAllCheckbox: 'components-contractList-selectAllCheckbox',
      selectAllCheckboxLabel: 'components-contractList-selectAllCheckboxLabel',
    },

    contractCard: {
      cardWrapper: 'components-contractCard-cardWrapper',
      dialogDetailsButton: 'components-contractCard-dialogDetailsButton',
      dialogInsuranceButton: 'components-contractCard-dialogInsuranceButton',
      dialogSoftwareButton: 'components-contractCard-dialogSoftwareButton',
    },

    specialContractCard: {
      cardWrapper: 'components-specialContractCard-cardWrapper',
      dialogDetailsButton: 'components-specialContractCard-dialogDetailsButton',
    },

    contractDialog: 'components-contractDialog',

    specialContractDialog: 'components-specialContractDialog',

    insuranceFilter: 'components-insuranceFilter',

    basket: {
      basketText: 'component-basket-basketText',
      wrapper: 'component-basket-wrapper',
      submit: 'component-basket-submit',
    },

    regionSelect: {
      wrapper: 'components-regionSelect-wrapper',
      option: 'components-regionSelect-option',
      optionPassive: 'components-regionSelect-optionPassive',
      submitButton: 'components-regionSelect-submitButton',
    },
  },

  views: {
    contact: {
      pageWrapper: 'views-contact-pageWrapper',
    },

    contract: {
      pageWrapper: 'views-contract-pageWrapper',
      heroTeaser: 'views-contract-heroTeaser',
      itWorksElement: 'views-contract-itWorks',
    },

    contractForm: {
      pageWrapper: 'views-contractForm-pageWrapper',
    },

    membershipForm: {
      pageWrapper: 'views-membershipForm-pageWrapper',
    },

    contractList: {
      pageWrapper: 'views-contractList-pageWrapper',
    },

    formSuccess: {
      pageWrapper: 'views-formSuccess-pageWrapper',
    },

    gtc: {
      pageWrapper: 'views-gtc-pageWrapper',
    },

    home: {
      pageWrapper: 'views-home-pageWrapper',
      heroTeaser: 'views-home-heroTeaser',
      contractsButton: 'view-home-contractsButton',
      membershipButton: 'view-home-membershipButton',
    },

    legalNotice: {
      pageWrapper: 'views-legalNotice-pageWrapper',
    },

    membership: {
      pageWrapper: 'views-membership-pageWrapper',
      heroTeaser: 'views-membership-heroTeaser',
      itWorksElement: 'views-membership-itWorksElement',
    },

    errorView: {
      pageWrapper: 'views-error-pageWrapper',
      backToStartButton: 'views-error-backToStartButton',
    },

    privacy: {
      pageWrapper: 'views-privacy-pageWrapper',
    },
  },
}
