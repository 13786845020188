import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'
import { COLORS } from 'src/theme/colors'
import { SHADOWS } from 'src/theme/shadows'
import { TYPOGRAPHY } from 'src/theme/typography'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    specialContractCardRoot: {
      borderRadius: 0,

      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.borderRadius.medium,
      },
    },

    cardContentMobileRoot: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(2.5),
      },
    },

    disabled: {
      backgroundColor: COLORS.accent.light,
    },

    cardImg: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
    },

    bold: {
      fontWeight: TYPOGRAPHY.fontWeightBold,
    },

    gradientBorderWrapperRoot: {
      boxShadow: SHADOWS.SOFT_SHADOWS[10],

      [theme.breakpoints.down('xs')]: {
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
      },
    },

    switchRoot: {
      margin: '-12px', // compensate the default padding (12px) of the switch
    },

    linkRoot: {
      fontWeight: TYPOGRAPHY.fontWeightBold,
    },
  }),
  { name: 'ContractCard' },
)

export default useStyles
