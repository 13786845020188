import type { ComponentProps, FC } from 'react'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import useStyles from './Image.styles'

interface ImageProps extends ComponentProps<'img'> {}

const Image: FC<ImageProps> = ({ alt, className, src, ...props }) => {
  const classes = useStyles()
  const [imageSource, setImageSource] = useState(src)

  useEffect(() => {
    setImageSource(src)
  }, [src])

  const handleImageLoadingError = () => {
    setImageSource(
      `${process.env.PUBLIC_URL + '/static/images/placeholder.png'}`,
    )
  }

  return (
    <img
      alt={alt} // Fixes warning for missing alt property on the img-tag. It is included within the ImageProps-Spread though.
      className={clsx(classes.root, className)}
      src={imageSource}
      onError={handleImageLoadingError}
      {...props}
    />
  )
}

export default Image
