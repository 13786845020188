import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const tagsPrimary = ['h1', 'h2'] as const
  const tagsSecondary = ['h3', 'h4'] as const

  const nestedRules: Record<string, any> = {}

  tagsPrimary.forEach((tag) => {
    nestedRules[`& ${tag}`] = {
      color: theme.palette.primary.main,
      marginTop: '0.35em',
      ...theme.typography[tag],
    }
  })

  tagsSecondary.forEach((tag) => {
    nestedRules[`& ${tag}`] = {
      color: theme.palette.text.primary,
      marginTop: '0.35em',
      ...theme.typography[tag],
    }
  })

  nestedRules['& ul'] = {
    marginLeft: theme.spacing(1),
  }

  nestedRules['& ol'] = {
    fontSize: 'larger',
    marginLeft: theme.spacing(1),
  }

  nestedRules['& li'] = {
    marginLeft: theme.spacing(3),
  }

  return {
    root: nestedRules,
    textPageStyles: {
      '& h2': {
        margin: theme.spacing(2, 0),
      },
      '& h3': {
        margin: theme.spacing(4, 0, 2),
      },
      '& h4': {
        margin: theme.spacing(2, 0, 0),
      },
    },
  }
})

export default useStyles
