import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { BorderRadiusSize } from 'src/theme/border-radius'
import { GradientName } from 'src/theme/gradients'

import { useStyles } from './GradientBorderWrapper.styles'

type GradientBorderWrapperProps = {
  children: ReactNode
  className?: string
  gradientName?: GradientName
  borderRadius?: BorderRadiusSize
  borderWidth?: string
  transparent?: boolean
  hideOverflow?: boolean
}

/**
 * GradientBorderWrapper component wraps its children with a gradient border.
 *
 * @param children - The content to be wrapped by the gradient border.
 * @param [className] - Additional class names to apply to the wrapper.
 * @param [gradientName] - The name of the gradient to be applied to the border.
 * @param [borderRadius] - The radius of the border corners.
 * @param [borderWidth] - The width of the border.
 * @param [transparent] - If true, the background will be transparent.
 * @param [hideOverflow] - If true, hides overflowing children.
 *
 * @returns The wrapped content with a gradient border.
 */

const GradientBorderWrapper: React.FC<GradientBorderWrapperProps> = ({
  children,
  className,
  gradientName = 'primary',
  borderRadius = 'medium',
  borderWidth = '0.5rem', // 8px
  transparent = false,
  hideOverflow = false,
}) => {
  const classes = useStyles({
    borderRadius,
    borderWidth,
    gradientName,
    hideOverflow,
    transparent,
  })

  return <div className={clsx(classes.root, className)}>{children}</div>
}

export default GradientBorderWrapper
