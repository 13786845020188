import type { ChangeEvent, FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { captureException } from '@sentry/react'
import clsx from 'clsx'
import { Formik, FormikErrors, FormikHelpers } from 'formik'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import { Region } from 'src/types'
import { scrollTo } from 'src/utils/form.utils'
import { useLocalStorageState } from 'src/hooks/useForm'
import useRegions from 'src/hooks/useRegions'
import useRelease from 'src/hooks/useRelease'
import api from 'src/services/api'
import PanelSummaryContent from 'src/components/PanelSummaryContent/PanelSummaryContent'

import { PanelParticipationReqType } from '../ContractForm/PanelParticipationReq/PanelParticipationReq'

import PanelAddressData, {
  initialValues as initialValuesAddressData,
  PanelAddressDataType,
  validationSchema as validationSchemaAddressData,
} from './PanelAddressData/PanelAddressData'
import PanelBankDetail, {
  initialValues as initialValuesBankDetail,
  PanelBankDetailsType,
  validationSchema as validationSchemaBankDetail,
} from './PanelBankDetail/PanelBankDetail'
import {
  BonusDataType,
  initialValues as initialValuesBonusData,
} from './PanelCheckData/BonusData'
import PanelCheckData, {
  initialValues as initialValuesCheckData,
  PanelCheckDataType,
  validationSchema as validationSchemaCheckData,
} from './PanelCheckData/PanelCheckData'
import {
  initialValues as initialValuesPtqzData,
  PtqzDataType,
} from './PanelCheckData/PtqzData'
import PanelPersonalData, {
  initialValues as initialValuesPersonalData,
  PanelPersonalDataType,
  validationSchema as validationSchemaPersonalData,
} from './PanelPersonalData/PanelPersonalData'
import PanelTypeOfMembership, {
  initialValues as initialValuesTypeOfMembership,
  PanelTypeOfMembershipType,
  validationSchema as validationSchemaTypeOfMembership,
} from './PanelTypeOfMembership/PanelTypeOfMembership'

import useStyles from './MembershipForm.styles'

const ComponentTestIds = TestIds.components

const defaultInitialValues = {
  ...initialValuesTypeOfMembership,
  ...initialValuesPersonalData,
  ...initialValuesAddressData,
  ...initialValuesBankDetail,
  ...initialValuesCheckData,
  ...initialValuesBonusData,
  ...initialValuesPtqzData,
}

//! Note: The order of the validation schemas must be in order their related panels.
const validationSchemas = (region: Region) => [
  validationSchemaTypeOfMembership,
  validationSchemaPersonalData,
  validationSchemaAddressData,
  validationSchemaBankDetail,
  validationSchemaCheckData(
    region.membershipBonus,
    region.membershipApplicationSurveyOptions,
  ),
]

export interface MembershipFormType
  extends PanelTypeOfMembershipType,
    PanelPersonalDataType,
    PanelAddressDataType,
    PanelParticipationReqType,
    PanelBankDetailsType,
    BonusDataType,
    PtqzDataType,
    PanelCheckDataType {}

export interface MembershipFormProps {
  className?: string
  initialValues?: MembershipFormType
}

export const LOCAL_STORAGE_KEY = 'membershipForm'

export const MembershipForm: FC<MembershipFormProps> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [expandedPanel, setExpandedPanel] = useState(1)
  const [completedPanel, setCompletedPanel] = useState(0)

  const { state: releaseState } = useRelease()
  const params = useParams<{ regionSlug: string }>()
  const history = useHistory()
  const confirm = useConfirm()
  const releaseId = releaseState.release?.id || 0
  const redirectPath = (applicationId: number) =>
    generatePath(routes.root.routes!.membership.routes!.formSuccess.path, {
      applicationId,
      regionSlug: params.regionSlug,
    })
  const { getters: regionGetters } = useRegions()
  const currentRegion = regionGetters.getRegion({
    by: 'slug',
    value: params.regionSlug || '',
  })
  const isLastPanel = currentRegion
    ? validationSchemas(currentRegion).length === expandedPanel
    : false
  const memberships = currentRegion?.regionalMemberships
  const membershipInformation = currentRegion?.membershipInformation
  const ptqzMembershipActive = currentRegion?.ptqzMembershipActive

  const initialValues = {
    ...defaultInitialValues,
    ...props.initialValues,
  } as MembershipFormType

  const { localStorageState: initialValuesState, handleUpdateForm } =
    useLocalStorageState({
      storageKey: LOCAL_STORAGE_KEY,
      values: initialValues,
    })

  const scrollToAccordion = (panelNo: number) =>
    scrollTo(ComponentTestIds.membershipForm.panel + `_${panelNo}`)
  // Change expanded panel and update the used validation schema accordingly.
  const handlePanelChange =
    (panelNo: number) => (event: ChangeEvent<{}>, expanded: boolean) => {
      // Only expand previous panels (only edit complete panels).
      if (expanded && panelNo < expandedPanel) {
        setExpandedPanel(panelNo)
        scrollToAccordion(panelNo)
      }
    }

  // Scrolls to the first input with an error.
  const scrollToError = (errors: FormikErrors<MembershipFormType>) => {
    const keys = Object.keys(errors)
    const selector = `[name="${keys[0]}"]`
    const errorElement = document.querySelector(selector)

    errorElement?.scrollIntoView()
  }

  const handleEmptyPanel = () => {
    setExpandedPanel(expandedPanel + 1)
  }
  // set initial validation schema
  const validationSchema = currentRegion
    ? validationSchemas(currentRegion)[expandedPanel - 1]
    : []
  useEffect(() => {
    let tmpCompletedPanel =
      expandedPanel - completedPanel > 1 ? completedPanel + 1 : completedPanel
    setCompletedPanel(tmpCompletedPanel)
  }, [expandedPanel, completedPanel])

  // Handles advancing through the form when the submit button of each panel was pressed.
  const onSubmit = async (
    values: MembershipFormType,
    formikHelpers: FormikHelpers<MembershipFormType>,
  ) => {
    const cleanValues =
      currentRegion &&
      validationSchemas(currentRegion).reduce((acc, schema) => {
        return { ...acc, ...schema.cast(values) }
      }, {})
    values = { ...values, ...cleanValues }
    if (isLastPanel) {
      await api
        .postMembershipParticipationForm({
          releaseId,
          values,
        })
        .then(({ data }) => {
          history.push(redirectPath(data.applicationId))
        })
        .catch((error) => {
          captureException(error)
          confirm({
            cancellationButtonProps: { style: { display: 'none' } },
            content: (
              <Alert severity="error">
                <AlertTitle>{t('General.somethingWentWrong')}</AlertTitle>
              </Alert>
            ),
            title: t('General.error'),
          })
        })
    } else {
      formikHelpers.setTouched({})
      const nextExpandedPanel = expandedPanel + 1
      if (nextExpandedPanel - completedPanel === 2) {
        setExpandedPanel(nextExpandedPanel)
        scrollToAccordion(nextExpandedPanel)
      } else {
        setExpandedPanel(completedPanel + 1)
        scrollToAccordion(completedPanel + 1)
      }
    }
  }

  return (
    <Formik
      initialValues={initialValuesState as MembershipFormType}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <form
            className={clsx(classes.root, className)}
            data-test-id={ComponentTestIds.membershipForm.wrapper}
            noValidate
            onBlur={() => handleUpdateForm(formik.values)}
            onSubmit={(event) => {
              if (!formik.isValid) scrollToError(formik.errors)
              formik.handleSubmit(event)
            }}
            {...props}
          >
            <Accordion
              data-test-id={ComponentTestIds.membershipForm.panel}
              id={ComponentTestIds.membershipForm.panel + `_${1}`}
              expanded={expandedPanel === 1}
              onChange={handlePanelChange(1)}
              TransitionProps={{
                timeout: 0,
              }}
              elevation={10}
              classes={{
                root: clsx(
                  completedPanel >= 1 && classes.accordionRootCompleted,
                ),
              }}
            >
              <AccordionSummary
                classes={{ content: classes.accordionSummaryContent }}
              >
                <PanelSummaryContent
                  expandedPanel={expandedPanel}
                  completed={completedPanel >= 1}
                  panelNo={1}
                  title={t('MembershipForm.typeOfMembership')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelTypeOfMembership
                  memberships={memberships}
                  membershipInformation={membershipInformation}
                  formik={formik}
                  setCompletedPanel={setCompletedPanel}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.membershipForm.panel}
              id={ComponentTestIds.membershipForm.panel + `_${2}`}
              expanded={expandedPanel === 2}
              onChange={handlePanelChange(2)}
              TransitionProps={{
                timeout: 0,
              }}
              elevation={10}
              classes={{
                root: clsx(
                  completedPanel >= 2 && classes.accordionRootCompleted,
                ),
              }}
            >
              <AccordionSummary
                classes={{ content: classes.accordionSummaryContent }}
              >
                <PanelSummaryContent
                  completed={completedPanel >= 2}
                  expandedPanel={expandedPanel}
                  panelNo={2}
                  title={t('MembershipForm.personalData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelPersonalData formik={formik} />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.membershipForm.panel}
              id={ComponentTestIds.membershipForm.panel + `_${3}`}
              expanded={expandedPanel === 3}
              onChange={handlePanelChange(3)}
              TransitionProps={{
                timeout: 0,
              }}
              elevation={10}
              classes={{
                root: clsx(
                  completedPanel >= 3 && classes.accordionRootCompleted,
                ),
              }}
            >
              <AccordionSummary
                classes={{ content: classes.accordionSummaryContent }}
              >
                <PanelSummaryContent
                  completed={completedPanel >= 3}
                  expandedPanel={expandedPanel}
                  panelNo={3}
                  title={t('MembershipForm.addressData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelAddressData formik={formik} />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.membershipForm.panel}
              id={ComponentTestIds.membershipForm.panel + `_${4}`}
              expanded={expandedPanel === 4}
              onChange={handlePanelChange(4)}
              TransitionProps={{
                timeout: 0,
              }}
              elevation={10}
              classes={{
                root: clsx(
                  completedPanel >= 4 && classes.accordionRootCompleted,
                ),
              }}
            >
              <AccordionSummary
                classes={{ content: classes.accordionSummaryContent }}
              >
                <PanelSummaryContent
                  completed={completedPanel >= 4}
                  expandedPanel={expandedPanel}
                  panelNo={4}
                  title={t('MembershipForm.bankData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelBankDetail
                  formik={formik}
                  submitEmpty={handleEmptyPanel}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.membershipForm.panel}
              id={ComponentTestIds.membershipForm.panel + `_${5}`}
              expanded={expandedPanel === 5}
              onChange={handlePanelChange(5)}
              TransitionProps={{
                timeout: 0,
              }}
              elevation={10}
              classes={{
                root: clsx(
                  completedPanel >= 5 && classes.accordionRootCompleted,
                ),
              }}
            >
              <AccordionSummary
                classes={{ content: classes.accordionSummaryContent }}
              >
                <PanelSummaryContent
                  completed={completedPanel >= 5}
                  expandedPanel={expandedPanel}
                  panelNo={5}
                  title={t('MembershipForm.checkData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelCheckData
                  ptqzMembershipActive={ptqzMembershipActive}
                  membershipBonus={currentRegion?.membershipBonus}
                  regionalAssociationLabel={
                    currentRegion?.regionalAssociationLabel
                  }
                  regionalAssociationLabelCheckout={
                    currentRegion?.regionalAssociationLabelCheckoutMembership
                  }
                  formik={formik}
                  surveyOptions={
                    currentRegion?.membershipApplicationSurveyOptions
                  }
                  statuteUrl={currentRegion?.statuteUrl}
                  privacyPolicyUrl={currentRegion?.privacyPolicyUrl}
                />
              </AccordionDetails>
            </Accordion>
          </form>
        )
      }}
    </Formik>
  )
}

export default MembershipForm
