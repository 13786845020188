import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import ContactForm from 'src/components/ContactForm/ContactForm'
import ContactTabs from 'src/components/ContactTabs/ContactTabs'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'

import useStyles from './ContactView.styles'

const ContactTestIds = TestIds.views.contact

const ContactView: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Page
      data-test-id={ContactTestIds.pageWrapper}
      title={t('Views.Contact.title')}
    >
      <ContentStripe className={classes.title}>
        <Box mx="auto">
          <Typography variant="h1" color="primary" align="center">
            {t('Views.Contact.title')}
          </Typography>
        </Box>
      </ContentStripe>

      {/* Contact Tabs*/}
      <ContactTabs />

      {/* Contact Form */}
      <ContentStripe className={classes.contactFormContentStripContainer}>
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={12} container justifyContent="center">
              <Box pb={5}>
                <Typography variant="h2">
                  {t('Views.Contact.contactFormSectionTitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Card
                elevation={10}
                classes={{ root: classes.contactFormCardRoot }}
              >
                <CardContent>
                  <ContactForm />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </ContentStripe>
    </Page>
  )
}

export default ContactView
