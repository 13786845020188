// Disable import sorting to ensure polyfills are applied correctly.
/* eslint-disable simple-import-sort/imports */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import '@fontsource/pt-sans'
import 'src/services/i18n/i18n'
import 'src/assets/css/style.css'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import { ENVIRONMENT, SENTRY_URL } from 'src/constants'
import App from 'src/components/App/App'

import reportWebVitals from './reportWebVitals'
/* eslint-enable */

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
  })
} else {
  console.warn('Sentry is disabled.')
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,

  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
