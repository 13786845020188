import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  Hidden,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { format, sub } from 'date-fns'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'

import { customRegExp } from 'src/utils/form.utils'
import i18n from 'src/services/i18n/i18n'
import { PanelSubmitRow } from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow'
import { checkLanr } from 'src/components/ContractForm/validationSchema'
import { KeyboardDatePicker } from 'src/components/DatePicker/KeyboardDatePicker'

import { MembershipFormType } from '../MembershipForm'

import useStyles from '../MembershipForm.styles'

const today = new Date()
const maxDOB = format(sub(today, { years: 18 }), 'yyyy-MM-dd')
const minDOB = format(sub(today, { years: 100 }), 'yyyy-MM-dd')

export interface PanelPersonalDataType {
  title: string
  firstName: string
  lastName: string
  email: string
  dateOfBirth: any
  lanrId: string
  medicalSpecialisation: string
  efnId: string
}
/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelPersonalDataType = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: null, // Must be 'null' or the field is pre-filled with todays date.
  lanrId: '',
  medicalSpecialisation: '',
  efnId: '',
}

export const validationSchema = Yup.object().shape({
  title: Yup.string().max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  firstName: Yup.string()
    .required(i18n.t('MembershipForm.Fields.PersonalData.FirstName.required'))
    .min(2, i18n.t('MembershipForm.Fields.PersonalData.FirstName.required'))
    .max(100, i18n.t('General.Form.maxLength', { length: 100 })),
  lastName: Yup.string()
    .required(i18n.t('MembershipForm.Fields.PersonalData.LastName.required'))
    .min(2, i18n.t('MembershipForm.Fields.PersonalData.LastName.required'))
    .max(100, i18n.t('General.Form.maxLength', { length: 100 })),
  email: Yup.string()
    .required(i18n.t('MembershipForm.Fields.PersonalData.Email.required'))
    .matches(
      customRegExp.email,
      i18n.t('MembershipForm.Fields.PersonalData.Email.format'),
    ),
  dateOfBirth: Yup.date()
    .typeError(i18n.t('MembershipForm.Fields.PersonalData.DateOfBirth.invalid'))
    .nullable()
    .max(maxDOB, i18n.t('MembershipForm.Fields.PersonalData.DateOfBirth.max'))
    .required(
      i18n.t('MembershipForm.Fields.PersonalData.DateOfBirth.required'),
    ),
  lanrId: Yup.string()
    .min(7, i18n.t('MembershipForm.Fields.PersonalData.LanrId.min'))
    .max(9, i18n.t('MembershipForm.Fields.PersonalData.LanrId.max'))
    .matches(/^\d+$/, i18n.t('MembershipForm.Fields.PersonalData.LanrId.type'))
    .test(
      'Validate LANR',
      i18n.t('ContractForm.Fields.PersonalData.LanrId.valid'),
      (lanrInput) => checkLanr(lanrInput),
    ),
  medicalSpecialisation: Yup.string().max(
    255,
    i18n.t('General.Form.maxLength', { length: 255 }),
  ),
  efnId: Yup.string()
    .min(15, i18n.t('MembershipForm.Fields.PersonalData.EfnId.min'))
    .max(15, i18n.t('MembershipForm.Fields.PersonalData.EfnId.max'))
    .matches(/^\d+$/, i18n.t('MembershipForm.Fields.PersonalData.EfnId.type')),
})
/* eslint-enable */

export interface PanelPersonalDataProps {
  className?: string
  formik: FormikProps<MembershipFormType>
}

export const PanelPersonalData: FC<PanelPersonalDataProps> = ({
  className,
  formik,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(formik.touched.title && formik.errors.title)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.title && formik.errors.title}
            label={t('MembershipForm.Fields.PersonalData.Title.label')}
            name="title"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.title}
            variant="outlined"
          />
        </Grid>

        <Grid item md={10} smDown component={Hidden} implementation="css" />

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            fullWidth
            inputProps={{ maxLength: 100 }}
            helperText={formik.touched.firstName && formik.errors.firstName}
            label={t('MembershipForm.Fields.PersonalData.FirstName.label')}
            name="firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.firstName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            fullWidth
            inputProps={{ maxLength: 100 }}
            helperText={formik.touched.lastName && formik.errors.lastName}
            label={t('MembershipForm.Fields.PersonalData.LastName.label')}
            name="lastName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.lastName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            label={t('MembershipForm.Fields.PersonalData.Email.label')}
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.email}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <KeyboardDatePicker
            error={Boolean(
              formik.touched.dateOfBirth && formik.errors.dateOfBirth,
            )}
            helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            label={t('MembershipForm.Fields.PersonalData.DateOfBirth.label')}
            maxDate={maxDOB}
            minDate={minDOB}
            name="dateOfBirth"
            onBlur={formik.handleBlur}
            onDateChange={(value) => formik.setFieldValue('dateOfBirth', value)}
            value={formik.values.dateOfBirth}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.lanrId && formik.errors.lanrId)}
            fullWidth
            inputProps={{ maxLength: 9 }}
            helperText={formik.touched.lanrId && formik.errors.lanrId}
            label={t('MembershipForm.Fields.PersonalData.LanrId.label')}
            name="lanrId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lanrId}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.medicalSpecialisation &&
                formik.errors.medicalSpecialisation,
            )}
            fullWidth
            inputProps={{ maxLength: 255 }}
            helperText={
              formik.touched.medicalSpecialisation &&
              formik.errors.medicalSpecialisation
            }
            label={t(
              'MembershipForm.Fields.PersonalData.MedicalSpecialisation.label',
            )}
            name="medicalSpecialisation"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.medicalSpecialisation}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.efnId && formik.errors.efnId)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      t('MembershipForm.Fields.PersonalData.EfnId.tooltip') ||
                      ''
                    }
                    arrow
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            inputProps={{ maxLength: 15 }}
            helperText={formik.touched.efnId && formik.errors.efnId}
            label={t('MembershipForm.Fields.PersonalData.EfnId.label')}
            name="efnId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.efnId}
            variant="outlined"
          />
          <Box mt={1}>
            <Typography color="textPrimary" component="span" variant="caption">
              {t('MembershipForm.Fields.PersonalData.EfnId.hint')}
            </Typography>
          </Box>
        </Grid>

        <PanelSubmitRow />
      </Grid>
    </fieldset>
  )
}

export default PanelPersonalData
