import { makeStyles } from '@material-ui/core'

import { BORDER_RADIUS, BorderRadiusSize } from 'src/theme/border-radius'
import { GradientName, GRADIENTS } from 'src/theme/gradients'

type StylesProps = {
  gradientName: GradientName
  borderRadius: BorderRadiusSize
  borderWidth: string
  transparent: boolean
  hideOverflow: boolean
}

export const useStyles = makeStyles(
  () => ({
    root: {
      border: (props: StylesProps) => `double ${props.borderWidth} transparent`,
      borderRadius: (props: StylesProps) => BORDER_RADIUS[props.borderRadius],
      backgroundImage: (props: StylesProps) =>
        props.transparent
          ? 'none'
          : 'linear-gradient(white, white), ' + GRADIENTS[props.gradientName],
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      overflow: (props: StylesProps) =>
        props.hideOverflow ? 'hidden' : 'visible',
    },
  }),
  {
    name: 'GradientBorderWrapper',
  },
)
