import type { FC } from 'react'
import React from 'react'
import type { BoxProps, ContainerProps } from '@material-ui/core'
import { Box, Container } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './ContentStripe.styles'

export interface ContentStripeProps
  extends Omit<BoxProps, 'children' | 'maxWidth'>,
    Pick<ContainerProps, 'children' | 'disableGutters' | 'fixed' | 'maxWidth'> {
  alt?: string
  backgroundColor?:
    | 'default'
    | 'primary'
    | 'primaryLight'
    | 'secondary'
    | 'fog'
    | 'image'
    | 'accent'
    | 'accentLight'
  backgroundImg?: string
  fullHeight?: boolean
}

export const ContentStripe: FC<ContentStripeProps> = ({
  alt = '',
  backgroundColor,
  backgroundImg,
  children,
  className,
  disableGutters,
  fixed,
  fullHeight,
  maxWidth = 'lg',
  ...props
}) => {
  const classes = useStyles()
  let backgroundClass: string = classes.backgroundDefault

  switch (backgroundColor) {
    case 'default':
      backgroundClass = classes.backgroundDefault

      break
    case 'primary':
      backgroundClass = classes.backgroundPrimary

      break

    case 'primaryLight':
      backgroundClass = classes.backgroundPrimaryLight

      break

    case 'secondary':
      backgroundClass = classes.backgroundSecondary

      break
    case 'accent':
      backgroundClass = classes.backgroundAccent

      break

    case 'accentLight':
      backgroundClass = classes.backgroundAccentLight

      break

    case 'fog':
      backgroundClass = classes.backgroundFog

      break

    case 'image':
      backgroundClass = classes.backgroundImage

      break

    default:
      backgroundClass = classes.backgroundDefault
  }

  return (
    <Box
      className={clsx(
        classes.root,
        backgroundClass,
        fullHeight && classes.fullHeight,
        className,
      )}
      py={5}
      {...props}
    >
      {!!backgroundImg && (
        <img className={classes.image} src={backgroundImg} alt={alt} />
      )}

      <Container
        className={clsx(classes.container)}
        disableGutters={disableGutters}
        fixed={fixed}
        maxWidth={maxWidth}
      >
        {children}
      </Container>
    </Box>
  )
}

export default ContentStripe
