import { colors } from '@material-ui/core'

export const COLORS = {
  // palette colors explained:
  // https://mui.com/material-ui/customization/palette/#color-tokens

  action: {
    active: '#123e59',
  },

  background: {
    default: colors.common.white,
    paper: colors.common.white,
  },

  primary: {
    main: '#0000b4',
    light: '#F1F8FF',
    dark: '#00007a',
    contrastText: '#fff',
  },

  secondary: {
    main: '#1c1c1c',
    light: '#555555',
    dark: '#000000',
    contrastText: '#ffffff',
  },

  // fixme: what's the difference between fog and tertiary?
  // maybe we only need "fog"?

  fog: {
    main: '#f1f8ff',
    light: '#f5f8fc', // not provided by the style guide
    dark: '#dde6ef', // not provided by the style guide
    contrastText: '#121212',
  },

  tertiary: {
    main: '#f1f8ff',
    light: '#fcfdff', // not provided by the style guide
    dark: '#d5e9ff', // not provided by the style guide
    contrastText: '#121212',
  },

  accent: {
    main: '#89CCCA',
    light: '#F3FAFA',
    dark: '#6FA9A7',
    contrastText: '#121212',
  },

  lightGrey: {
    main: '#EDEDED',
    light: '#EDEDED',
    dark: '#EDEDED',
    contrastText: '#EDEDED',
  },

  text: {
    primary: '#121212',
    secondary: '#757575',
  },
}
