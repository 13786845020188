import i18n from 'i18next'
import * as Yup from 'yup'

export const validateLanrId = Yup.string()
  .min(7, i18n.t('ContractForm.Fields.PersonalData.LanrId.min'))
  .max(9, i18n.t('ContractForm.Fields.PersonalData.LanrId.max'))
  .matches(/^\d+$/, i18n.t('ContractForm.Fields.PersonalData.LanrId.type'))
  .test(
    'Validate LANR',
    i18n.t('ContractForm.Fields.PersonalData.LanrId.valid'),
    (lanrInput) => checkLanr(lanrInput),
  )

export const validateFirstName = Yup.string()
  .required(i18n.t('ContractForm.Fields.PersonalData.FirstName.required'))
  .min(2, i18n.t('General.Form.minLength', { length: 2 }))
  .max(100, i18n.t('General.Form.maxLength', { length: 100 }))

export const validateLastName = Yup.string()
  .required(i18n.t('ContractForm.Fields.PersonalData.LastName.required'))
  .min(2, i18n.t('General.Form.minLength', { length: 2 }))
  .max(100, i18n.t('General.Form.maxLength', { length: 100 }))

export const checkLanr = (lanr: string | undefined): boolean => {
  let checksum: number
  let calcResult = 0
  let curDigit: number

  if (lanr === undefined) {
    return true
  }

  // Validate the input to match either 9 or 7 digits
  if (/^\d{9}$/.test(lanr)) {
    lanr = lanr.substring(0, 7) // Trim to first 7 digits if 9 digits are provided
  }
  if (!/^\d{7}$/.test(lanr)) {
    return false
  } else {
    checksum = parseInt(lanr.substring(6, 7), 10)

    for (let i = 0; i < 6; i++) {
      curDigit = parseInt(lanr.substring(i, i + 1), 10)
      if (i % 2 === 0) {
        calcResult += curDigit * 4
      } else {
        calcResult += curDigit * 9
      }
    }

    calcResult %= 10
    if (calcResult > 0) {
      calcResult = 10 - calcResult
    }

    return calcResult === checksum
  }
}
