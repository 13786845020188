import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirm } from 'material-ui-confirm'
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'

import { TestIds } from 'src/testIds'
import { customRegExp } from 'src/utils/form.utils'
import useRegions from 'src/hooks/useRegions'
import useRelease from 'src/hooks/useRelease'
import { postContactRequest } from 'src/services/api/api'
import Button from 'src/components/Button/Button'

import useStyles from './ContactForm.styles'

const ComponentTestIds = TestIds.components

interface FormData {
  concern: string
  regionId: number | ''
  title: string
  firstName: string
  lastName: string
  email: string
  message: string
  phone: string
}

const INITIAL_VALUES: FormData = {
  concern: '',
  email: '',
  firstName: '',
  lastName: '',
  message: '',
  phone: '',
  regionId: '',
  title: '',
}

export const ContactForm: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { state: regionsState } = useRegions()
  const regions = regionsState.regions.filter(
    (region) => region.showOnContactView,
  )
  const { state: releaseState } = useRelease()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const confirm = useConfirm()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  const concernOptions = [
    { text: t('ContactForm.Fields.Concern.Options.general'), value: '1' },
    { text: t('ContactForm.Fields.Concern.Options.contract'), value: '2' },
    { text: t('ContactForm.Fields.Concern.Options.membership'), value: '3' },
    { text: t('ContactForm.Fields.Concern.Options.consulting'), value: '4' },
  ]
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(customRegExp.email, t('ContactForm.Fields.Email.format'))
      .required(t('ContactForm.Fields.Email.required')),
    firstName: Yup.string()
      .required(t('ContactForm.Fields.FirstName.required'))
      .max(100, t('General.Form.maxLength', { length: 100 })),
    lastName: Yup.string()
      .required(t('ContactForm.Fields.LastName.required'))
      .max(100, t('General.Form.maxLength', { length: 100 })),
    phone: Yup.string()
      .required(t('ContactForm.Fields.Phone.required'))
      .min(7, t('ContactForm.Fields.Phone.format'))
      .max(50, t('General.Form.maxLength', { length: 50 }))
      .matches(customRegExp.phone, t('ContactForm.Fields.Phone.format')),
    regionId: Yup.number().required(t('ContactForm.Fields.Region.required')),
    title: Yup.string().max(50, t('General.Form.maxLength', { length: 50 })),
  })

  const onSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    // Set release id
    values['releaseId'] = releaseState.release?.id

    // A regionId of 0 means, that no region was selected, so set default value '' here
    if (values['regionId'] === 0) {
      values['regionId'] = ''
    }

    // Post contact request
    await postContactRequest(values)
      .then(() => {
        helpers.resetForm()
        setShowSuccessMessage(true)
      })
      .catch(() =>
        confirm({
          cancellationButtonProps: { style: { display: 'none' } },
          content: (
            <Alert severity="error">
              <AlertTitle>{t('General.somethingWentWrong')}</AlertTitle>
            </Alert>
          ),
          title: t('General.error'),
        }),
      )
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setFieldValue,
  } = useFormik<FormData>({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validationSchema,
  })

  const handleCloseSuccessMessage = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setShowSuccessMessage(false)
  }

  const handleConcernChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    handleChange(e)

    const concernValue = e.target.value as FormData['concern']
    const defaultConcernMessage = t('ContactForm.pleaseContactMeMessage')
    if (concernValue === '4' && !values.message.trim()) {
      // set a default message when concern == 4 and the message is empty
      setFieldValue('message', defaultConcernMessage)
    } else if (
      concernValue !== '4' &&
      values.message === defaultConcernMessage
    ) {
      // reset the default message when concern changes and the message is unchanged
      setFieldValue('message', '')
    }
  }

  return (
    <>
      <form
        data-test-id={ComponentTestIds.contactForm.wrapper}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="releaseId" />
        {/* IHR ANLIEGEN */}
        <Box mb={5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {t('ContactForm.Sections.titleConcern')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={Boolean(touched.concern && errors.concern)}>
                <RadioGroup
                  name="concern"
                  onChange={handleConcernChange}
                  onBlur={handleBlur}
                  value={values.concern}
                >
                  <Box display="flex" flexWrap="wrap">
                    {concernOptions.map((option) => (
                      <FormControlLabel
                        classes={{
                          root: classes.formControlLabelConcern,
                        }}
                        key={option.value}
                        control={<Radio />}
                        label={option.text}
                        value={option.value}
                      />
                    ))}
                  </Box>
                </RadioGroup>
                {touched.concern && errors.concern && (
                  <FormHelperText>{errors.concern}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.message && errors.message)}
                fullWidth
                helperText={touched.message && errors.message}
                label={t('ContactForm.Fields.Message.label')}
                multiline
                name="message"
                onBlur={handleBlur}
                onChange={handleChange}
                minRows={6}
                maxRows={10}
                value={values.message}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>

        {/* REGION */}
        <Box mb={5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {t('ContactForm.Sections.titleRegion')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.regionId && errors.regionId)}
                fullWidth
                label={t('ContactForm.Fields.Region.label')}
                name="regionId"
                helperText={touched.regionId && errors.regionId}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                select
                value={values.regionId}
                variant="outlined"
              >
                {regions.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h3">
              {t('ContactForm.Sections.titleContactData')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={Boolean(touched.title && errors.title)}
              inputProps={{ maxLength: 50 }}
              helperText={touched.title && errors.title}
              label="Titel"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              variant="outlined"
              fullWidth={!smUp}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              label={t('ContactForm.Fields.FirstName.label')}
              name="firstName"
              inputProps={{ maxLength: 100 }}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.firstName}
              variant="outlined"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              label={t('ContactForm.Fields.LastName.label')}
              name="lastName"
              inputProps={{ maxLength: 100 }}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.lastName}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label={t('ContactForm.Fields.Email.label')}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.email}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              inputProps={{ maxLength: 50 }}
              helperText={touched.phone && errors.phone}
              label={t('ContactForm.Fields.Phone.label')}
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.phone}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mt={5}>
              <Button
                data-test-id={ComponentTestIds.contactForm.submitButton}
                disabled={isSubmitting}
                size="small"
                type="submit"
                variant="contained"
                startIcon={
                  isSubmitting && <CircularProgress color="inherit" size={20} />
                }
              >
                {t('ContactForm.SubmitButton')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={4000}
        onClose={handleCloseSuccessMessage}
      >
        <Alert onClose={handleCloseSuccessMessage} severity="success">
          {t('ContactForm.Success.message')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ContactForm
