import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom'
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import Button from 'src/components/Button/Button'
import type { ContentStripeProps } from 'src/components/ContentStripe/ContentStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'

import useStyles from './Basket.styles'

const ComponentTestIds = TestIds.components

export interface BasketProps extends Omit<ContentStripeProps, 'children'> {
  numberOfContracts: number
}

export const Basket: FC<BasketProps> = ({ numberOfContracts, ...props }) => {
  const classes = useStyles()
  const params = useParams<{ regionSlug: string }>()
  const { t } = useTranslation()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const redirectPath = generatePath(
    routes.root.routes!.contracts.routes!.contractForm.path,
    {
      regionSlug: params.regionSlug,
    },
  )

  return (
    <ContentStripe
      className={clsx(classes.root)}
      data-test-id={ComponentTestIds.basket.wrapper}
      // The Id is required so that the height of the element can be recorded.
      id="basketWrapper"
      {...props}
    >
      <Grid container alignItems="center" spacing={smUp ? 0 : 2}>
        <Grid item xs={12} sm={6}>
          <Typography
            data-test-id={ComponentTestIds.basket.basketText}
            gutterBottom
            variant="subtitle1"
            className={clsx(classes.basketText)}
            align={smUp ? 'left' : 'center'}
          >
            {t('Basket.contractsSelected', {
              count: numberOfContracts,
            })}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={smUp ? 'flex-end' : 'center'}
        >
          <Button
            component={RouterLink}
            data-test-id={ComponentTestIds.basket.submit}
            to={redirectPath}
            variant="contained"
          >
            {t('Basket.button')}
          </Button>
        </Grid>
      </Grid>
    </ContentStripe>
  )
}

export default Basket
