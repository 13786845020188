import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import clsx from 'clsx'

import useRegions from 'src/hooks/useRegions'
import Button from 'src/components/Button/Button'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import CustomIcon from 'src/components/CustomIcon/CustomIcon'

import useStyles from './ContactTabs.styles'

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
  activeTab: number
}

function TabPanel({
  children,
  value,
  index,
  activeTab,
  ...otherProps
}: TabPanelProps) {
  const classes = useStyles()

  const panelClass = activeTab === 0 ? classes.leftPanel : classes.rightPanel

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={`${classes.tabPanel} ${panelClass}`}
      {...otherProps}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    'aria-controls': `tabpanel-${index}`,
    id: `tab-${index}`,
  }
}

const ContactTabs: React.FC = () => {
  const [value, setValue] = useState(0)
  const classes = useStyles()
  const { t } = useTranslation()
  const { state: regionsState } = useRegions()

  const contractContacts = useMemo(() => {
    return [
      {
        name: t('Views.Contact.hzvContactTitle'),
        phone: t('Views.Contact.hzvContactPhone'),
        serviceHoursLine1: t('Views.Contact.hzvServiceHoursLine1'),
        serviceHoursLine2: t('Views.Contact.hzvServiceHoursLine2'),
      },
      ...regionsState.regions
        .filter((region) => region.showOnContactView && region.phoneContract)
        .sort((regionA, regionB) => (regionA.name < regionB.name ? -1 : 1))
        .map((region) => ({
          name: region.name,
          phone: region.phoneContract,
          serviceHoursLine1: region.serviceHoursRow1,
          serviceHoursLine2: region.serviceHoursRow2,
        })),
    ]
  }, [regionsState.regions, t])

  const memberContacts = useMemo(() => {
    return regionsState.regions
      .filter((region) => region.showOnContactView)
      .sort((regionA, regionB) => (regionA.name < regionB.name ? -1 : 1))
      .map((region) => ({
        name: region.name,
        phonePrimary: region.phonePrimary,
        phoneSecondary: region.phoneSecondary,
      }))
  }, [regionsState.regions])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  // Removes spaces, hyphens and slashes from phone numbers to be able to use it as phone links
  const formatPhoneNumber = (phone: string): string => {
    return phone.replace(/[\s-/]/g, '')
  }

  return (
    <div className={classes.tabsContainer}>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        classes={{ root: classes.tabsRoot }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          label={t('Views.Contact.MemberTab')}
          {...a11yProps(0)}
          classes={{
            root: clsx(classes.tabRoot, classes.tabLeft, {
              [classes.tabInactive]: value !== 0,
            }),
          }}
        />
        <Tab
          label={t('Views.Contact.ContractsTab')}
          {...a11yProps(1)}
          classes={{
            root: clsx(classes.tabRoot, classes.tabRight, {
              [classes.tabInactive]: value !== 1,
            }),
          }}
        />
      </Tabs>

      {/* Members Tab */}
      <TabPanel value={value} index={0} activeTab={value}>
        <ContentStripe backgroundColor="primaryLight">
          <Grid
            className={classes.contactCardsContainer}
            container
            justifyContent="center"
            spacing={5}
          >
            {memberContacts.map((contact) => (
              <Grid item xs={12} sm={6} lg={4} key={contact.name}>
                <Box className={classes.contactCard}>
                  <Typography variant="h3">{contact.name}</Typography>
                  <Typography variant="subtitle1">
                    <CustomIcon
                      iconName="telefon"
                      color="textPrimary"
                      style={{ fontSize: 'inherit' }}
                    />
                    <a
                      className={classes.phoneNumber}
                      href={`tel:${formatPhoneNumber(contact.phonePrimary)}`}
                    >
                      {contact.phonePrimary}
                    </a>
                  </Typography>
                  {contact.phoneSecondary && (
                    <Typography variant="subtitle1">
                      <CustomIcon
                        iconName="telefon"
                        color="textPrimary"
                        style={{ fontSize: 'inherit' }}
                      />
                      <a
                        className={classes.phoneNumber}
                        href={`tel:${formatPhoneNumber(
                          contact.phoneSecondary,
                        )}`}
                      >
                        {contact.phoneSecondary}
                      </a>
                    </Typography>
                  )}
                </Box>
              </Grid>
            ))}
            <Grid item xs={12} container>
              <Button
                className={classes.moreContactsButton}
                variant="contained"
                color="primary"
                href="https://www.haev.de/ueber-uns/landesverbaende"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Views.Contact.allContactsButton')}
              </Button>
            </Grid>
          </Grid>
        </ContentStripe>
      </TabPanel>

      {/* Contracts Tab */}
      <TabPanel value={value} index={1} activeTab={value}>
        <ContentStripe backgroundColor="accentLight">
          <Grid
            className={classes.contactCardsContainer}
            container
            justifyContent="center"
            spacing={5}
          >
            {contractContacts.map((contact) => (
              <Grid item xs={12} md={6} key={contact.name}>
                <Box className={classes.contactCard}>
                  <Typography variant="h3">{contact.name}</Typography>
                  {contact.serviceHoursLine1 && (
                    <Typography
                      className={classes.serviceHours}
                      variant="subtitle1"
                      component="span"
                      dangerouslySetInnerHTML={{
                        __html: contact.serviceHoursLine1,
                      }}
                    ></Typography>
                  )}
                  {contact.serviceHoursLine2 && (
                    <Typography
                      className={classes.serviceHours}
                      variant="subtitle1"
                      component="span"
                      dangerouslySetInnerHTML={{
                        __html: contact.serviceHoursLine2,
                      }}
                    ></Typography>
                  )}
                  <Typography variant="h3">
                    <CustomIcon
                      iconName="telefon"
                      color="textPrimary"
                      style={{ fontSize: 'inherit' }}
                    />
                    <a
                      className={classes.phoneNumber}
                      href={`tel:${formatPhoneNumber(contact.phone as string)}`}
                    >
                      {contact.phone}
                    </a>
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </ContentStripe>
      </TabPanel>
    </div>
  )
}

export default ContactTabs
