import * as React from 'react'
import { Box, Typography } from '@material-ui/core'

import ContentStripe from '../ContentStripe/ContentStripe'

import useStyles from './LegalDocument.styles'

type Props = { title: string; children: NonNullable<React.ReactNode> }

export const LegalDocument = (props: Props) => {
  const classes = useStyles()

  const { title, children } = props

  return (
    <>
      <ContentStripe className={classes.titlePadding}>
        <Box className={classes.titleCenter}>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            dangerouslySetInnerHTML={{ __html: title }}
          ></Typography>
        </Box>
      </ContentStripe>

      <ContentStripe>
        <Box className={classes.contentPadding}>{children}</Box>
      </ContentStripe>
    </>
  )
}
