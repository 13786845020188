import type { ElementType, ForwardedRef } from 'react'
import React from 'react'
import {
  Button as MUIButton, // eslint-disable-line no-restricted-imports
  ButtonProps as MUIButtonProps,
  PropTypes,
} from '@material-ui/core'
import { Omit } from '@material-ui/types'
import clsx from 'clsx'

import useStyles from './Button.styles'

/**
 * @property color
 *
 *  Default: 'inherit', 'primary', 'secondary', 'default'
 *
 *  Custom: 'tertiary', 'accent'
 */
export interface ButtonProps
  extends Omit<MUIButtonProps<ElementType>, 'color'> {
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'default'
    | 'tertiary'
    | 'accent'
}

export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({ children, color, ...props }, ref) => {
    const classes = useStyles()
    // remove custom colors as they are not supported by MUIButton
    const muiColorProp = [
      'inherit',
      'primary',
      'secondary',
      'default',
    ].includes(color || '')
      ? (color as PropTypes.Color)
      : undefined

    return (
      <MUIButton
        ref={ref as ForwardedRef<HTMLButtonElement>}
        classes={{
          // set custom styles depending on the color
          contained: clsx({
            [classes.secondary]: color === 'secondary',
            [classes.tertiary]: color === 'tertiary',
            [classes.accent]: color === 'accent',
          }),
        }}
        color={muiColorProp}
        disableElevation
        {...props}
      >
        {children}
      </MUIButton>
    )
  },
)

export default Button
