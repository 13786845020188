import type { FC } from 'react'
import React from 'react'
import type { BoxProps } from '@material-ui/core'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

import type { NavItemProps } from 'src/components/NavItem/NavItem'
import NavItem from 'src/components/NavItem/NavItem'

import useStyles from './NavBar.styles'

export interface NavBarProps extends BoxProps {
  className?: string
  navItems: NavItemProps[]
  size?: 'medium' | 'large' | 'small'
}

export const NavBar: FC<NavBarProps> = ({
  className,
  navItems,
  size,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Box component="nav" className={clsx(classes.root, className)} {...props}>
      {navItems.map((navItem) => (
        <NavItem
          key={navItem.title}
          size={size}
          title={navItem.title}
          to={navItem.to}
          className={classes.navItem}
        />
      ))}
    </Box>
  )
}

export default NavBar
