import type { ComponentProps, FC } from 'react'
import React from 'react'
import clsx from 'clsx'

import useStyles from './Logo.styles'

export interface LogoProps extends ComponentProps<'img'> {
  className?: string
  height?: string | number
  variant?:
    | 'svg'
    | 'svg-icon'
    | 'dhv-white'
    | 'haevg-white'
    | 'dhv-primary-black'
    | 'hzv-accent-black'
  width?: string | number
}

export const Logo: FC<LogoProps> = ({
  className,
  height,
  variant,
  width,
  ...props
}) => {
  const classes = useStyles()
  let src: string = ''

  switch (variant) {
    case 'svg-icon':
      src = '/static/logo/dhv-logo-icon.svg'

      break
    case 'haevg-white':
      src = '/static/logo/haevg-logo-white.svg'

      break
    case 'dhv-white':
      src = '/static/logo/dhv-logo-white.svg'

      break
    case 'dhv-primary-black':
      src = '/static/logo/dhv-logo-primary-black.svg'

      break
    case 'hzv-accent-black':
      src = '/static/logo/hzv-logo-accent-black.svg'

      break
    default:
      src = '/static/logo/dhv-logo-white.svg'
  }

  return (
    <img
      alt="Logo"
      className={clsx(classes.root, className)}
      height={height}
      src={src}
      width={width}
      {...props}
    />
  )
}

export default Logo
