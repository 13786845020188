import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: theme.spacing(5),

      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(20),
      },
    },

    hero: {
      padding: theme.spacing(10, 0),
    },

    infoContainer: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10, 0),
      },

      '& .MuiContainer-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

    gradientWrapper: {
      boxShadow: theme.shadows[10],

      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        borderLeft: 0,
        borderRight: 0,
      },
    },

    infoBox: {
      padding: theme.spacing(5),
    },

    buttonContainer: {
      padding: theme.spacing(10, 0),
    },

    buttonWrapper: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2.5),

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },

    whatsNextContainer: {
      textAlign: 'center',

      padding: theme.spacing(10, 0),
    },

    whatsNextWrapper: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(7.5, 0),
      },
    },

    whatsNextStep: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5),
      },
    },

    contractInformation: {
      textAlign: 'left',

      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
      },
    },

    newsletterContainer: {
      padding: theme.spacing(10, 0, 20),
    },
  }),

  { name: 'FormSuccessView' },
)

export default useStyles
