import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterLink } from 'react-router-dom'
import type { AppBarProps } from '@material-ui/core'
import {
  AppBar,
  Container,
  Grid,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
  useTheme,
} from '@material-ui/core'
import { Close as CloseIcon, Menu as MenuIcon } from '@material-ui/icons'
import clsx from 'clsx'

import routes from 'src/routes'
import useUserData from 'src/hooks/useUserDate'
import Button from 'src/components/Button/Button'
import CustomIcon from 'src/components/CustomIcon/CustomIcon'
import NavDrawer from 'src/components/NavDrawer/NavDrawer'
import type { NavItemProps } from 'src/components/NavItem/NavItem'

import useStyles from './TopBar.styles'

export interface TopBarProps extends AppBarProps {
  className?: string
}

export const TopBar: FC<TopBarProps> = ({ className, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { hasMembershipStatus } = useUserData()
  const theme = useTheme()

  const homeNavItem: NavItemProps = {
    title: t('TopBar.NavItems.Home.title'),
    to: routes.root.path,
  }

  const rightNavItems: NavItemProps[] = []

  if (!hasMembershipStatus) {
    rightNavItems.push({
      title: t('TopBar.NavItems.Membership.title'),
      to: routes.root.routes!.membership.path,
    })
  }

  rightNavItems.push({
    title: t('TopBar.NavItems.Contracts.title'),
    to: routes.root.routes!.contracts.path,
  })

  return (
    <AppBar elevation={0} className={clsx(classes.root, className)} {...props}>
      <Toolbar
        id="drawer-container"
        style={{ position: 'relative' }}
        className={classes.toolbar}
        disableGutters
      >
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                className={classes.homeButton}
                component={RouterLink}
                to={homeNavItem.to}
              >
                {homeNavItem.title}
              </Button>
            </Grid>
            <Hidden smDown>
              <Grid className={classes.navBarContainer} item>
                <Button
                  activeClassName={classes.active}
                  component={RouterLink}
                  size="small"
                  to={rightNavItems[0].to}
                  startIcon={
                    <CustomIcon
                      iconName={'mitgliedWerden'}
                      color="textPrimary"
                      style={{
                        fontSize: '22px',
                        fontWeight: theme.typography.fontWeightRegular,
                      }}
                    />
                  }
                >
                  {rightNavItems[0].title}
                </Button>
                <Button
                  activeClassName={classes.active}
                  component={RouterLink}
                  size="small"
                  to={rightNavItems[1].to}
                  startIcon={
                    <CustomIcon
                      iconName={'hzvTeilnahme'}
                      color="textPrimary"
                      style={{
                        fontSize: '24px',
                        fontWeight: theme.typography.fontWeightRegular,
                      }}
                    />
                  }
                >
                  {rightNavItems[1].title}
                </Button>
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <NavDrawer
                classes={{
                  modal: classes.drawerModal,
                  paper: classes.drawer,
                  paperAnchorTop: classes.drawerAnchor,
                }}
                navItems={rightNavItems}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
              />

              <Grid item style={{ marginLeft: 'auto' }}>
                <IconButton
                  color="default"
                  onClick={() => setDrawerOpen(!drawerOpen)}
                >
                  <SvgIcon fontSize="small">
                    {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                  </SvgIcon>
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
