import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField, Typography } from '@material-ui/core'
import type { FormikProps } from 'formik'
import IBAN from 'iban'
import * as Yup from 'yup'

import { removeNullCharacters } from 'src/utils/removeNullCharacters'
import i18n from 'src/services/i18n/i18n'
import { PanelSubmitRow } from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow'

import { ContractFormType } from '../ContractForm'

import useStyles from '../ContractForm.styles'

export interface PanelBankDetailsType {
  accountHolder: string
  bankName: string
  iban: string
  bic: string
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelBankDetailsType = {
  accountHolder: '',
  bankName: '',
  iban: '',
  bic: '',
}

export const validationSchema = Yup.object().shape({
  accountHolder: Yup.string()
    .required(i18n.t('ContractForm.Fields.BankData.AccountHolder.required'))
    .min(2, i18n.t('ContractForm.Fields.BankData.AccountHolder.required'))
    .max(255, i18n.t('General.Form.maxLength', { length: 255 })),
  bankName: Yup.string()
    .required(i18n.t('ContractForm.Fields.BankData.BankName.required'))
    .min(2, i18n.t('ContractForm.Fields.BankData.BankName.required'))
    .max(250, i18n.t('General.Form.maxLength', { length: 250 }))
    .transform((current) => removeNullCharacters(current)),
  iban: Yup.string()
    .required(i18n.t('ContractForm.Fields.BankData.Iban.required'))
    .min(2, i18n.t('ContractForm.Fields.BankData.Iban.required'))
    .transform((current) => removeNullCharacters(current))
    .test(
      'iban',
      i18n.t('ContractForm.Fields.BankData.Iban.required'),
      (ibanString) => (ibanString ? IBAN.isValid(ibanString) : false),
    ),
  bic: Yup.string()
    .min(8, i18n.t('ContractForm.Fields.BankData.Bic.format'))
    .max(11, i18n.t('ContractForm.Fields.BankData.Bic.max'))
    .transform((current) => removeNullCharacters(current)),
})
/* eslint-enable */

export interface PanelBankDetailProps {
  className?: string
  formik: FormikProps<ContractFormType>
}

export const PanelBankDetail: FC<PanelBankDetailProps> = ({
  className,
  formik,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.accountHolder && formik.errors.accountHolder,
            )}
            fullWidth
            inputProps={{ maxLength: 255 }}
            helperText={
              formik.touched.accountHolder && formik.errors.accountHolder
            }
            label={t('ContractForm.Fields.BankData.AccountHolder.label')}
            name="accountHolder"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.accountHolder}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bankName && formik.errors.bankName)}
            fullWidth
            inputProps={{ maxLength: 250 }}
            helperText={formik.touched.bankName && formik.errors.bankName}
            label={t('ContractForm.Fields.BankData.BankName.label')}
            name="bankName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.bankName}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.iban && formik.errors.iban)}
            fullWidth
            inputProps={{ maxLength: 34 }}
            helperText={formik.touched.iban && formik.errors.iban}
            label={t('ContractForm.Fields.BankData.Iban.label')}
            name="iban"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.iban}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bic && formik.errors.bic)}
            fullWidth
            helperText={formik.touched.bic && formik.errors.bic}
            label={t('ContractForm.Fields.BankData.Bic.label')}
            name="bic"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bic}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">
            {t('ContractForm.differentBankAccountsInformation')}
          </Typography>
        </Grid>

        <PanelSubmitRow />
      </Grid>
    </fieldset>
  )
}

export default PanelBankDetail
