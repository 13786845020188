import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'

import { customRegExp } from 'src/utils/form.utils'
import i18n from 'src/services/i18n/i18n'
import { PanelSubmitRow } from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow'
import { KeyboardDatePicker } from 'src/components/DatePicker/KeyboardDatePicker'

import { MembershipFormType } from '../MembershipForm'

import useStyles from '../MembershipForm.styles'

export interface PanelAddressDataType {
  personalStreet: string
  personalStreetNo: string
  personalCity: string
  personalPostalCode: string
  personalPhone: string
  personalPhoneMobile: string
  personalFax: string
  bsName: string
  bsType: string
  bsId: string
  bsActiveSince: any
  bsStreet: string
  bsStreetNo: string
  bsCity: string
  bsPostalCode: string
  bsPhoneOffice: string
  bsPhoneMobile: string
  bsFax: string
  bsEmail: string
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelAddressDataType = {
  personalStreet: '',
  personalStreetNo: '',
  personalCity: '',
  personalPostalCode: '',
  personalPhone: '',
  personalPhoneMobile: '',
  personalFax: '',
  bsName: '',
  bsType: '',
  bsId: '',
  bsActiveSince: null, // Must be 'null' or the field is pre-filled with todays date.
  bsStreet: '',
  bsStreetNo: '',
  bsCity: '',
  bsPostalCode: '',
  bsPhoneOffice: '',
  bsPhoneMobile: '',
  bsFax: '',
  bsEmail: '',
}

export const validationSchema = Yup.object().shape({
  personalStreet: Yup.string()
    .required(
      i18n.t('MembershipForm.Fields.AddressData.PersonalStreet.required'),
    )
    .min(2, i18n.t('MembershipForm.Fields.AddressData.PersonalStreet.required'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  personalStreetNo: Yup.string()
    .required(
      i18n.t('MembershipForm.Fields.AddressData.PersonalStreetNo.required'),
    )
    .max(7, i18n.t('MembershipForm.Fields.AddressData.PersonalStreetNo.max')),
  personalCity: Yup.string()
    .required(i18n.t('MembershipForm.Fields.AddressData.PersonalCity.required'))
    .min(2, i18n.t('MembershipForm.Fields.AddressData.PersonalCity.required'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  personalPostalCode: Yup.string()
    .required(
      i18n.t('MembershipForm.Fields.AddressData.PersonalPostalCode.required'),
    )
    .matches(
      /^[0-9]{5}$/,
      i18n.t('MembershipForm.Fields.AddressData.PersonalPostalCode.match'),
    ),
  personalPhone: Yup.string()
    .required(
      i18n.t('MembershipForm.Fields.AddressData.PersonalPhone.required'),
    )
    .min(7, i18n.t('MembershipForm.Fields.AddressData.PersonalPhone.format'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .matches(
      customRegExp.phone,
      i18n.t('MembershipForm.Fields.AddressData.PersonalPhone.format'),
    ),
  personalPhoneMobile: Yup.string()
    .min(
      7,
      i18n.t('MembershipForm.Fields.AddressData.PersonalPhoneMobile.format'),
    )
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .matches(
      customRegExp.phone,
      i18n.t('MembershipForm.Fields.AddressData.PersonalPhoneMobile.format'),
    ),
  personalFax: Yup.string()
    .min(7, i18n.t('MembershipForm.Fields.AddressData.PersonalFax.format'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .matches(
      customRegExp.phone,
      i18n.t('MembershipForm.Fields.AddressData.PersonalFax.format'),
    ),
  bsName: Yup.string().max(
    255,
    i18n.t('General.Form.maxLength', { length: 255 }),
  ),
  bsType: Yup.string(),
  bsId: Yup.string()
    .min(7, i18n.t('MembershipForm.Fields.AddressData.BsId.min'))
    .max(9, i18n.t('MembershipForm.Fields.AddressData.BsId.max'))
    .matches(/^\d+$/, i18n.t('MembershipForm.Fields.AddressData.BsId.type'))
    .when('regionalMembershipIsPracticeAddressRequired', {
      is: (regionalMembershipIsPracticeAddressRequired: boolean) =>
        regionalMembershipIsPracticeAddressRequired,
      then: (schema) =>
        schema.required(
          i18n.t('MembershipForm.Fields.AddressData.BsId.required'),
        ),
    }),
  bsActiveSince: Yup.date()
    .typeError(
      i18n.t('MembershipForm.Fields.AddressData.BsActiveSince.invalid'),
    )
    .nullable(),
  bsStreet: Yup.string()
    .min(2, i18n.t('MembershipForm.Fields.AddressData.BsStreet.required'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .when('regionalMembershipIsPracticeAddressRequired', {
      is: (regionalMembershipIsPracticeAddressRequired: boolean) =>
        regionalMembershipIsPracticeAddressRequired,
      then: (schema) =>
        schema.required(
          i18n.t('MembershipForm.Fields.AddressData.BsStreet.required'),
        ),
    }),
  bsStreetNo: Yup.string()
    .max(7, i18n.t('MembershipForm.Fields.AddressData.BsStreetNo.max'))
    .when('regionalMembershipIsPracticeAddressRequired', {
      is: (regionalMembershipIsPracticeAddressRequired: boolean) =>
        regionalMembershipIsPracticeAddressRequired,
      then: (schema) =>
        schema.required(
          i18n.t('MembershipForm.Fields.AddressData.BsStreetNo.required'),
        ),
    }),
  bsCity: Yup.string()
    .min(2, i18n.t('MembershipForm.Fields.AddressData.BsCity.required'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .when('regionalMembershipIsPracticeAddressRequired', {
      is: (regionalMembershipIsPracticeAddressRequired: boolean) =>
        regionalMembershipIsPracticeAddressRequired,
      then: (schema) =>
        schema.required(
          i18n.t('MembershipForm.Fields.AddressData.BsCity.required'),
        ),
    }),
  bsPostalCode: Yup.string()
    .matches(
      /^[0-9]{5}$/,
      i18n.t('MembershipForm.Fields.AddressData.BsPostalCode.match'),
    )
    .when('regionalMembershipIsPracticeAddressRequired', {
      is: (regionalMembershipIsPracticeAddressRequired: boolean) =>
        regionalMembershipIsPracticeAddressRequired,
      then: (schema) =>
        schema.required(
          i18n.t('MembershipForm.Fields.AddressData.BsPostalCode.required'),
        ),
    }),
  bsPhoneOffice: Yup.string()
    .min(7, i18n.t('MembershipForm.Fields.AddressData.BsPhoneOffice.format'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .matches(
      customRegExp.phone,
      i18n.t('MembershipForm.Fields.AddressData.BsPhoneOffice.format'),
    ),
  bsPhoneMobile: Yup.string()
    .min(7, i18n.t('MembershipForm.Fields.AddressData.BsPhoneMobile.format'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .matches(
      customRegExp.phone,
      i18n.t('MembershipForm.Fields.AddressData.BsPhoneMobile.format'),
    ),
  bsFax: Yup.string()
    .min(7, i18n.t('MembershipForm.Fields.AddressData.BsFax.format'))
    .max(50, i18n.t('General.Form.maxLength', { length: 50 }))
    .matches(
      customRegExp.phone,
      i18n.t('MembershipForm.Fields.AddressData.BsFax.format'),
    ),
  // we use a custom regex instead of yups email() as backend does not allow single top level domains, but email() does
  bsEmail: Yup.string()
    .matches(
      customRegExp.email,
      i18n.t('MembershipForm.Fields.AddressData.BsEmail.format'),
    )
    .when('regionalMembershipIsPracticeAddressRequired', {
      is: (regionalMembershipIsPracticeAddressRequired: boolean) =>
        regionalMembershipIsPracticeAddressRequired,
      then: (schema) =>
        schema.required(
          i18n.t('MembershipForm.Fields.AddressData.BsEmail.required'),
        ),
    }),
})

/* eslint-enable */

export interface PanelAddressDataProps {
  className?: string
  formik: FormikProps<MembershipFormType>
}

export const PanelAddressData: FC<PanelAddressDataProps> = ({
  className,
  formik,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={1} style={{ paddingBottom: 30 }}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold">
              {t('MembershipForm.privateAddress')}
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            error={Boolean(
              formik.touched.personalStreet && formik.errors.personalStreet,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.personalStreet && formik.errors.personalStreet
            }
            label={t('MembershipForm.Fields.AddressData.PersonalStreet.label')}
            name="personalStreet"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.personalStreet}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(
              formik.touched.personalStreetNo && formik.errors.personalStreetNo,
            )}
            fullWidth
            inputProps={{ maxLength: 7 }}
            helperText={
              formik.touched.personalStreetNo && formik.errors.personalStreetNo
            }
            label={t(
              'MembershipForm.Fields.AddressData.PersonalStreetNo.label',
            )}
            name="personalStreetNo"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.personalStreetNo}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(
              formik.touched.personalPostalCode &&
                formik.errors.personalPostalCode,
            )}
            fullWidth
            inputProps={{ maxLength: 5 }}
            helperText={
              formik.touched.personalPostalCode &&
              formik.errors.personalPostalCode
            }
            label={t(
              'MembershipForm.Fields.AddressData.PersonalPostalCode.label',
            )}
            name="personalPostalCode"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.personalPostalCode}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            error={Boolean(
              formik.touched.personalCity && formik.errors.personalCity,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.personalCity && formik.errors.personalCity
            }
            label={t('MembershipForm.Fields.AddressData.PersonalCity.label')}
            name="personalCity"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.personalCity}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.personalPhone && formik.errors.personalPhone,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.personalPhone && formik.errors.personalPhone
            }
            label={t('MembershipForm.Fields.AddressData.PersonalPhone.label')}
            name="personalPhone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.personalPhone}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.personalPhoneMobile &&
                formik.errors.personalPhoneMobile,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.personalPhoneMobile &&
              formik.errors.personalPhoneMobile
            }
            label={t(
              'MembershipForm.Fields.AddressData.PersonalPhoneMobile.label',
            )}
            name="personalPhoneMobile"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.personalPhoneMobile}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.personalFax && formik.errors.personalFax,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.personalFax && formik.errors.personalFax}
            label={t('MembershipForm.Fields.AddressData.PersonalFax.label')}
            name="personalFax"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.personalFax}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold">
              {formik.values.regionalMembershipPracticeAddressLabel
                ? formik.values.regionalMembershipPracticeAddressLabel
                : t('MembershipForm.officeAddress')}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <FormControl
              error={Boolean(formik.touched.bsType && formik.errors.bsType)}
              variant="outlined"
              required={
                formik.values.regionalMembershipIsPracticeAddressRequired
              }
            >
              <RadioGroup
                name="bsType"
                onChange={formik.handleChange}
                row
                value={formik.values.bsType}
              >
                <FormControlLabel
                  control={<Radio />}
                  label={t(
                    'MembershipForm.Fields.AddressData.BsType.individualPractice',
                  )}
                  value="1"
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t(
                    'MembershipForm.Fields.AddressData.BsType.professionalPracticeGroup',
                  )}
                  value="2"
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t(
                    'MembershipForm.Fields.AddressData.BsType.communityOfPractice',
                  )}
                  value="3"
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t('MembershipForm.Fields.AddressData.BsType.mvz')}
                  value="4"
                />
              </RadioGroup>

              {formik.touched.bsType && formik.errors.bsType && (
                <FormHelperText>{formik.errors.bsType}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bsName && formik.errors.bsName)}
            fullWidth
            inputProps={{ maxLength: 255 }}
            helperText={formik.touched.bsName && formik.errors.bsName}
            label={t('MembershipForm.Fields.AddressData.BsName.label')}
            name="bsName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsName}
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} smDown component={Hidden} implementation="css" />

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bsId && formik.errors.bsId)}
            fullWidth
            inputProps={{ maxLength: 9 }}
            helperText={formik.touched.bsId && formik.errors.bsId}
            label={t('MembershipForm.Fields.AddressData.BsId.label')}
            name="bsId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsId}
            variant="outlined"
            required={formik.values.regionalMembershipIsPracticeAddressRequired}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <KeyboardDatePicker
            error={Boolean(
              formik.touched.bsActiveSince && formik.errors.bsActiveSince,
            )}
            helperText={
              formik.touched.bsActiveSince && formik.errors.bsActiveSince
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      t(
                        'MembershipForm.Fields.AddressData.BsActiveSince.tooltip',
                      ) || ''
                    }
                    arrow
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            label={t('MembershipForm.Fields.AddressData.BsActiveSince.label')}
            name="bsActiveSince"
            onBlur={formik.handleBlur}
            onDateChange={(value) =>
              formik.setFieldValue('bsActiveSince', value)
            }
            value={formik.values.bsActiveSince}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            error={Boolean(formik.touched.bsStreet && formik.errors.bsStreet)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.bsStreet && formik.errors.bsStreet}
            label={t('MembershipForm.Fields.AddressData.BsStreet.label')}
            name="bsStreet"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsStreet}
            variant="outlined"
            required={formik.values.regionalMembershipIsPracticeAddressRequired}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(
              formik.touched.bsStreetNo && formik.errors.bsStreetNo,
            )}
            fullWidth
            inputProps={{ maxLength: 7 }}
            helperText={formik.touched.bsStreetNo && formik.errors.bsStreetNo}
            label={t('MembershipForm.Fields.AddressData.BsStreetNo.label')}
            name="bsStreetNo"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsStreetNo}
            variant="outlined"
            required={formik.values.regionalMembershipIsPracticeAddressRequired}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            error={Boolean(
              formik.touched.bsPostalCode && formik.errors.bsPostalCode,
            )}
            fullWidth
            inputProps={{ maxLength: 5 }}
            helperText={
              formik.touched.bsPostalCode && formik.errors.bsPostalCode
            }
            label={t('MembershipForm.Fields.AddressData.BsPostalCode.label')}
            name="bsPostalCode"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsPostalCode}
            variant="outlined"
            required={formik.values.regionalMembershipIsPracticeAddressRequired}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            error={Boolean(formik.touched.bsCity && formik.errors.bsCity)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.bsCity && formik.errors.bsCity}
            label={t('MembershipForm.Fields.AddressData.BsCity.label')}
            name="bsCity"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsCity}
            variant="outlined"
            required={formik.values.regionalMembershipIsPracticeAddressRequired}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.bsPhoneOffice && formik.errors.bsPhoneOffice,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.bsPhoneOffice && formik.errors.bsPhoneOffice
            }
            label={t('MembershipForm.Fields.AddressData.BsPhoneOffice.label')}
            name="bsPhoneOffice"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsPhoneOffice}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(
              formik.touched.bsPhoneMobile && formik.errors.bsPhoneMobile,
            )}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={
              formik.touched.bsPhoneMobile && formik.errors.bsPhoneMobile
            }
            label={t('MembershipForm.Fields.AddressData.BsPhoneMobile.label')}
            name="bsPhoneMobile"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsPhoneMobile}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bsFax && formik.errors.bsFax)}
            fullWidth
            inputProps={{ maxLength: 50 }}
            helperText={formik.touched.bsFax && formik.errors.bsFax}
            label={t('MembershipForm.Fields.AddressData.BsFax.label')}
            name="bsFax"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsFax}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            error={Boolean(formik.touched.bsEmail && formik.errors.bsEmail)}
            fullWidth
            helperText={formik.touched.bsEmail && formik.errors.bsEmail}
            label={t('MembershipForm.Fields.AddressData.BsEmail.label')}
            name="bsEmail"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bsEmail}
            variant="outlined"
            required={formik.values.regionalMembershipIsPracticeAddressRequired}
          />
        </Grid>

        <PanelSubmitRow />
      </Grid>
    </fieldset>
  )
}

export default PanelAddressData
